import ApiService from "../base/api_service";

export const getJurnal = async (accessToken, params) => {
    try {

        const response = await ApiService.get(`/jurnal`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const exportJurnalV2 = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/jurnal/export`, {
            params: params,
            responseType: 'blob',
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response;
    }
}   
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BtnBack from "../../../../components/buttons/btn_back";
import BtnDefault from "../../../../components/buttons/btn_default";
import CustomHeader from "../../../../components/customs/header";
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import { Helmet } from 'react-helmet';
import { faEdit, faEye, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { getAccount } from "../../../../services/finance/account_service";
import { useNavigate } from "react-router-dom";
import InputSearch from "../../../../components/form/input_search";
import AccountCreate from "./create";
import AccountUpdate from "./update";
import CustomAlert from "../../../../components/customs/alert";

const Account = () => {

    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openCreate, setOpenCreate] = useState(false)

    const [data, setData] = useState([])
    const [keyword, setKeyword] = useState("")

    const filteredData = data.filter(item =>
        item.name.toLowerCase().includes(keyword.toLowerCase()) ||
        item.id.toLowerCase().includes(keyword.toLowerCase())
    ); // Filter data berdasarkan search term

    const fetchData = async (accessToken) => {
        setLoading(true)
        try {
            const response = await getAccount(accessToken)
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken)
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')

        setAccessToken(getAccessToken)
        fetchData(getAccessToken)
    }, [])

    const [dataUpdate, setDataUpdate] = useState(null)
    const [openUpdate, setOpenUpdate] = useState(false)

    const [alert, setAlert] = useState(false)
    const [alertType, setAlertType] = useState("")
    const [alertMessage, setAlertMessage] = useState("")

    return (
        <>
            <Helmet>
                <title>Data Akun Keuangan</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center">Data Akun Keuangan</p>
                    <div className="flex items-center justify-end">
                        <BtnDefault handleClick={() => setOpenCreate(true)}>
                            <FontAwesomeIcon icon={faPlus} />
                            <p className="hidden md:flex">Akun baru</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="sticky top-16 left-0 p-3 bg-white z-10">
                    <InputSearch
                        keyword={keyword}
                        handleSearch={(e) => setKeyword(e.target.value)}
                    />
                </div>

                <div className="w-full px-3 pb-5">
                    <table className={`max-w-screen-2xl ${loading || data.length === 0 ? 'min-w-full' : 'min-w-max md:min-w-full'}`}>
                        <thead className="sticky top-[124px] md:top-[118px] z-10">
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 ">Id</th>
                                <th className="px-3 ">Name</th>
                                <th className="px-3 ">Kategori</th>
                                <th className="px-3 py-2 rounded-tr-xl">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ?
                                    Array.from({ length: 20 }).map((_, index) => (
                                        <tr className="animate-pulse">
                                            {Array.from({ length: 5 }).map((_, index) => (
                                                <td key={index} className="px-3 py-2">
                                                    <div className="w-full h-5 bg-gray-300 rounded-full"></div>
                                                </td>
                                            ))}
                                        </tr>
                                    ))
                                    :
                                    filteredData.map((item, index) => ( // Ganti data dengan filteredData
                                        <tr key={index} className={`${index % 2 === 1 ? 'bg-gray-200' : ''} hover:bg-gray-300 transition-all duration-300`}>
                                            <td className="text-center px-3 py-2">{index + 1}</td>
                                            <td className="px-3 py-2">{item?.id}</td>
                                            <td className="px-3 py-2 uppercase">{item?.name}</td>
                                            <td className="px-3 py-2">{item?.post_type}</td>
                                            <td className="px-3 py-2 w-24 ">
                                                {!["1-201", "1-202", "1-301", "2-101", "3-101", "3-102", "3-103", "3-104", "3-105",].includes(item?.id) && (
                                                    <BtnDefault
                                                        addClass="w-full bg-yellow-500 hover:bg-yellow-600 text-white"
                                                        handleClick={() => {
                                                            setDataUpdate({
                                                                id: item.id,
                                                                name: item.name,
                                                                category: item.category,
                                                                post_type: item.post_type
                                                            })
                                                            setOpenUpdate(true)
                                                        }}
                                                    >
                                                        <FontAwesomeIcon icon={faEdit} />
                                                    </BtnDefault>
                                                )}
                                            </td>
                                        </tr>
                                    ))}
                            <tr className="bg-custom-dark-yellow">
                                <td className="py-4" colSpan={10} />
                            </tr>
                        </tbody>
                    </table>
                </div>

                <AccountCreate
                    open={openCreate}
                    setOpen={setOpenCreate}
                    setRefresh={setRefresh}
                    accessToken={accessToken}
                    userRole={userRole}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                />

                <AccountUpdate
                    open={openUpdate}
                    setOpen={setOpenUpdate}
                    setRefresh={setRefresh}
                    dataUpdate={dataUpdate}
                    accessToken={accessToken}
                    userRole={userRole}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                />

                <CustomAlert
                    open={alert}
                    setOpen={setAlert}
                    type={alertType}
                    message={alertMessage}
                />

            </LayoutDashboard>

        </>
    )
}

export default Account
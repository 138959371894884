import { useEffect, useState } from "react"
import InputDefault from "../../../components/form/input_default"
import SelectFilter from "../../../components/form/select_filter"
import LayoutModal from "../../../components/layouts/layout_modal"
import { getAccountBallances } from "../../../services/finance/account_service"
import BtnDefault from "../../../components/buttons/btn_default"
import CustomSpinner from "../../../components/customs/spinner"
import CustomCurrency from "../../../components/customs/currency"
import { postUnitSellingPayment } from "../../../services/unit/unit_selling_payment_service"

const NewSellingPayment = ({
    accessToken,
    open, setOpen, setRefresh,
    getSellingId, getTotalPayment,
    setOpenAlert, setMessageAlert, setTypeAlert
}) => {
    const [loading, setLoading] = useState(false)
    const [nominal, setNominal] = useState("0")
    const [errorNominal, setErrorNominal] = useState("")

    const [accountToId, setAccountToId] = useState("")
    const [errorAccountToId, setErrorAccountToId] = useState("")
    const [dataAccountBallance, setDataAccountBallance] = useState([])
    const [loadingAccountBallance, setLoadingAccountBallance] = useState(false)
    const fetchDataAccountBallance = async (accessToken) => {
        setLoadingAccountBallance(true)
        try {
            const response = await getAccountBallances({ accessToken });
            if (response.status === 200) {
                setDataAccountBallance(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBallance(false)
    };

    useEffect(() => {
        if (accessToken) {
            fetchDataAccountBallance(accessToken)
        }
    }, [accessToken])

    const handleChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setNominal(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const [date, setDate] = useState("")
    const [errorDate, setErrorDate] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorNominal(null)
            setErrorAccountToId(null)
            setErrorDate(null)

            if (!date) {
                setErrorDate("Tanggal tidak boleh kosong..!")
            } else if (Number(nominal) < 1) {
                setErrorNominal("Nominal tidak boleh kosong..!")
            } else if (Number(nominal) > getTotalPayment) {
                setErrorNominal("Nominal tidak boleh lebih besar dari total..!")
            } else if (accountToId === "") {
                setErrorAccountToId("Masuk ke tidak boleh kosong..!")
            } else {

                const formData = new FormData()
                formData.append('unit_selling_id', getSellingId)
                formData.append('nominal', Number(nominal))
                formData.append('account_id', accountToId)
                formData.append('date', date)
                const response = await postUnitSellingPayment(accessToken, formData)
                if (response.status === 201) {
                    setOpenAlert(true)
                    setMessageAlert("Berhasil menambahkan data pembayaran..!")
                    setTypeAlert("success")
                    setOpen(false)
                    setRefresh(true)
                } else if (response.status === 500) {
                    setOpenAlert(true)
                    setMessageAlert("Gagal menambahkan data pembayaran..!")
                    setTypeAlert("error")
                } else {
                    setOpenAlert(true)
                    setMessageAlert(response.data.message ?? "Gagal menambahkan data pembayaran..!")
                    setTypeAlert("error")
                }
            }
        } catch (error) {
            setOpenAlert(true)
            setMessageAlert("Gagal menambahkan data pembayaran")
            setTypeAlert("error")
        }
        setLoading(false)
    }


    return (
        <LayoutModal open={open} setOpen={setOpen} name={"Pembayaran Baru"}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                    <p className="text-sm font-semibold">Total Pembayaran</p>
                    <div className="flex items-center justify-end gap-3 px-3 rounded-full border-2 py-2 font-semibold">
                        <CustomCurrency value={getTotalPayment ?? 0} />
                    </div>
                </div>

                <InputDefault
                    id="nominal"
                    type='text'
                    label="Nominal"
                    value={formatCurrency(nominal)}
                    handleChange={handleChange}
                    errorMessage={errorNominal}
                    addClass={"text-right"}
                />

                <InputDefault
                    id="date"
                    type='date'
                    label="Date"
                    value={date}
                    handleChange={(e) => setDate(e.target.value)}
                    errorMessage={errorDate}
                />

                <SelectFilter
                    label="Masuk ke"
                    placeholder='Pilih kas / bank'
                    options={dataAccountBallance.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))}
                    value={accountToId}
                    onSelect={(e) => setAccountToId(e)}
                    errorMessage={errorAccountToId}

                />


                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                    }} addClass='bg-red-500 w-full  text-white'>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full text-white'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default NewSellingPayment
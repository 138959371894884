import { useEffect, useState } from 'react';
import LayoutModal from './../../../../components/layouts/layout_modal';
import { getAccountBallances, getAccountBeban } from '../../../../services/finance/account_service';
import SelectFilter from '../../../../components/form/select_filter';
import InputDefault from '../../../../components/form/input_default';
import BtnDefault from '../../../../components/buttons/btn_default';
import CustomSpinner from '../../../../components/customs/spinner';
import { useNavigate } from 'react-router-dom';
import TextAreaDefault from '../../../../components/form/text_area_default';
import { postProcurement } from '../../../../services/logistic/procurment_service';
import CustomCurrency from '../../../../components/customs/currency';
const ProcurementPayment = (
    {
        accessToken,
        open, setOpen,
        suplierId,
        items,
        totalPayment,
        date,
    }
) => {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [category, setCategory] = useState("pemasukan")
    
    const [accountToId, setAccountToId] = useState("")
    const [accountFromId, setAccountFromId] = useState("")
    const [debiturCrediturId, setDebiturCrediturId] = useState("")
    const [consumentId, setConsumentId] = useState("")
    const [unitId, setUnitId] = useState("")
    const [nominal, setNominal] = useState("0")
    const [description, setDescription] = useState("")

    const [errorCategory, setErrorCategory] = useState(null)
    const [errorAccountFromId, setErrorAccountFromId] = useState(null)
    const [errorAccountToId, setErrorAccountToId] = useState(null)
    const [errorDebiturCrediturId, setErrorDebiturCrediturId] = useState(null)
    const [errorNominal, setErrorNominal] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)


    const [loadingAccountBeban, setLoadingAccountBeban] = useState(false)
    const [loadingAccountBallance, setLoadingAccountBallance] = useState(false)

    const [dataAccountBeban, setDataAccountBeban] = useState([])
    const fetchDataAccountBeban = async (accessToken) => {
        setLoadingAccountBeban(true)
        try {
            const response = await getAccountBeban({ accessToken });
            if (response.status === 200) {
                setDataAccountBeban(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBeban(false)
    };

    const [dataAccountBallance, setDataAccountBallance] = useState([])
    const fetchDataAccountBallance = async (accessToken) => {
        setLoadingAccountBallance(true)
        try {
            const response = await getAccountBallances({ accessToken });
            if (response.status === 200) {
                setDataAccountBallance(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBallance(false)
    };

    useEffect(() => {
        if (accessToken) {
            fetchDataAccountBallance(accessToken)
            fetchDataAccountBeban(accessToken)
        } else {
            // navigate("/login")
        }
    }, [accessToken])

    const handleChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setNominal(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorAccountFromId(null)
            setErrorAccountToId(null)
            setErrorNominal(null)
            setErrorDescription(null)

            
            if (!accountToId) {
                setErrorAccountToId("Silahkan pilih kas / bank..!")
            } else if (!nominal || nominal === "0") {
                setErrorNominal("Silahkan masukkan nominal..!")
            } else if (nominal > totalPayment) {
                setErrorNominal("Nominal tidak boleh lebih besar dari total pembayaran..!")
            } else if (!description) {
                setErrorDescription("Silahkan masukkan keterangan..!")
            } else {
                const formData = new FormData()
                formData.append("suplier_id", suplierId)
                formData.append("date", date)
                items.forEach((item, index) => {
                    formData.append(`material[${index}][name]`, item.name);
                    formData.append(`material[${index}][unit]`, item.unit);
                    formData.append(`material[${index}][price]`, item.price);
                    formData.append(`material[${index}][quantity]`, item.quantity.toString());
                });

                formData.append("account_to_id", accountToId)
                formData.append("nominal", nominal)
                formData.append("description", description)

                const response = await postProcurement(accessToken, formData)
                if (response.status === 201) {
                    navigate("/dashboard/logistic/procurement")
                } else {
                    alert("Transaksi gagal disimpan..!")
                }
            }
        } catch (error) {
            alert("Transaksi gagal disimpan..!")
        }
        setLoading(false)
    }
    return (
        <LayoutModal open={open} setOpen={setOpen} name={"Pembayaran Pembelian"}>
            <form onSubmit={handleSubmit} className='flex flex-col gap-3'>


                <SelectFilter
                    label="Diambil dari"
                    placeholder='Pilih kas / bank'
                    options={dataAccountBallance.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))}
                    value={accountToId}
                    onSelect={(e) => setAccountToId(e)}
                    errorMessage={errorAccountToId}
                    loading={loadingAccountBallance}
                />

                <div className='flex items-center justify-between'>
                    <p className='font-semibold'>Total Pembayaran</p>
                    <p className='font-semibold'>
                        <CustomCurrency value={totalPayment} />
                    </p>
                </div>

                <InputDefault
                    id="nominal"
                    type='text'
                    label="Nominal"
                    addClass="text-right"
                    value={formatCurrency(nominal)}
                    handleChange={handleChange}
                    errorMessage={errorNominal ?? undefined}
                />

                <TextAreaDefault
                    id="note"
                    label="Keterangan"
                    value={description}
                    setValue={setDescription}
                    errorMessage={errorDescription}
                />

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default ProcurementPayment
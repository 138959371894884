import { useState } from "react"
import BtnDefault from "../../../components/buttons/btn_default"
import CustomSpinner from "../../../components/customs/spinner"
import { updatePassword } from "../../../services/user_management/profile_service"
import LayoutModal from "../../../components/layouts/layout_modal"
import InputDefault from "../../../components/form/input_default"

const UpdatePassword = ({ open, setOpen, accessToken, setAlert, setAlertType, setAlertMessage }) => {

    const [password, setPassword] = useState('')
    const [newPassword, setNewPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')

    const [passwordError, setPasswordError] = useState(null)
    const [newPasswordError, setNewPasswordError] = useState(null)
    const [confirmPasswordError, setConfirmPasswordError] = useState(null)

    const [inputType, setInputType] = useState('password')
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setPasswordError(null)
            setNewPasswordError(null)
            setConfirmPasswordError(null)

            if (password.length < 8) {
                setPasswordError('Password minimal 8 karakter..!')
            } else if (newPassword.length < 8) {
                setNewPasswordError('Password minimal 8 karakter..!')
            } else if (confirmPassword !== newPassword) {
                setConfirmPasswordError('Password tidak sama..!')
            } else {
                const formData = new FormData()
                formData.append('password', password)
                formData.append('new_password', newPassword)
                formData.append('new_password_confirmation', confirmPassword)

                const response = await updatePassword({ accessToken, data: formData })
                if (response.status === 200) {
                    setOpen(false)
                    setAlert(true)
                    setAlertType('success')
                    setAlertMessage('Password berhasil diubah..!')
                } else {
                    setAlert(true)
                    setAlertType('error')
                    setAlertMessage(response.data.message ?? "Password Gagal diubah..!")
                }
            }
        } catch (error) {
            setAlert(true)
            setAlertType('error')
            setAlertMessage('Silahkan periksa koneksi internet Anda..!')
        }
        setLoading(false)
    }
    return (
        <LayoutModal name="Update Password" open={open} setOpen={setOpen}>
            <form onSubmit={handleSubmit} className='flex flex-col gap-3'>
                <InputDefault
                    label="Password Lama"
                    placeholder="Masukkan password lama"
                    value={password}
                    setValue={setPassword}
                    type={inputType}
                    errorMessage={passwordError}
                />

                <InputDefault
                    label="Password Baru"
                    placeholder="Masukkan password baru"
                    value={newPassword}
                    setValue={setNewPassword}
                    type={inputType}
                    errorMessage={newPasswordError}
                />

                <InputDefault
                    label="Konfirmasi Password Baru"
                    placeholder="Masukkan konfirmasi password baru"
                    value={confirmPassword}
                    setValue={setConfirmPassword}
                    type={inputType}
                    errorMessage={confirmPasswordError}
                />

                <div className="flex items-center gap-3 ">
                    <input
                        type="checkbox" id="inputType"
                        checked={inputType === 'text'}
                        onChange={() => setInputType(inputType === 'password' ? 'text' : 'password')}
                    />
                    <label htmlFor="inputType" className="text-sm cursor-pointer">Tampilkan Password</label>
                </div>

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                        setPassword('')
                        setNewPassword('')
                        setConfirmPassword('')
                    }} addClass='bg-red-500 w-full  text-white'>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full text-white'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UpdatePassword
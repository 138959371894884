import { useEffect, useState } from "react"
import LayoutModal from "../../../components/layouts/layout_modal"
import BtnDefault from "../../../components/buttons/btn_default"
import CustomSpinner from "../../../components/customs/spinner"
import InputDefault from "../../../components/form/input_default"
import InputPassword from "../../../components/form/input_password"
import SelectDefault from "../../../components/form/select_default"
import { updateUser } from "../../../services/user_management/user_service"

const UserUpdate = (
    {
        open,
        setOpen,
        accessToken,
        setRefresh,
        getData,
        setOpenAlert,
        setTypeAlert,
        setMessageAlert,
        dataUserRole
    }
) => {
    const [loading, setLoading] = useState(false)
    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [roleId, setRoleId] = useState("")
    const [optionRole, setOptionRole] = useState([])

    useEffect(() => {
        if (getData) {
            setId(getData?.id)
            setName(getData?.name)
            setEmail(getData?.email)
            setRoleId(getData?.user_role?.id)
        }
    }, [getData])

    const [checked, setChecked] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            const formData = new FormData()
            formData.append("name", name)
            formData.append("email", email.toLowerCase())
            formData.append("role_id", roleId)
            if (checked) {
                formData.append("password", password)
            }
            const response = await updateUser(accessToken, formData, id)
            if (response.status === 200) {
                setRefresh(true)
                setOpen(false)
                setOpenAlert(true)
                setTypeAlert("success")
                setMessageAlert("Pengguna berhasil diubah..!")
            } else {
                setOpenAlert(true)
                setTypeAlert("error")
                setMessageAlert("Pengguna gagal diubah..!")
            }
        } catch (error) {
            setOpenAlert(true)
            setTypeAlert("error")
            setMessageAlert("Pengguna gagal diubah..!")
        }
        setLoading(false)
    }

    return (
        <LayoutModal name={"Update Pengguna"} open={open} setOpen={setOpen}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <InputDefault
                    id="name"
                    placeholder="Masukkan Nama"
                    label="Nama"
                    value={name}
                    setValue={setName}
                />

                <InputDefault
                    id="email"
                    placeholder="Masukkan Email"
                    label="Email"
                    value={email}
                    setValue={setEmail}
                    addClass="lowercase"
                />

                {dataUserRole &&
                    <SelectDefault
                        id="role_id"
                        label={"Role"}
                        value={roleId}
                        options={dataUserRole.map((item) => ({
                            label: item.name,
                            value: item.id
                        }))}
                        onSelect={(e) => setRoleId(e)}
                    />
                }


                <label htmlFor="checkbox-password" className="flex items-center gap-2 cursor-pointer">
                    <input
                        type="checkbox"
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                        id="checkbox-password" className="hidden" />
                    <div className={`w-12 h-5 rounded-full flex items-center relative transition-all duration-200 ${checked ? "bg-custom-navy" : "bg-gray-300"}`}>
                        <div className={`w-7 aspect-square rounded-full absolute transition-all duration-200 ${checked ? "right-0 bg-custom-dark-yellow" : "left-0 bg-custom-navy"}`} />
                    </div>
                    <span>
                        Atur Password
                    </span>
                </label>

                {checked &&
                    <InputPassword
                        id="password"
                        placeholder="Masukkan Password"
                        label="Password"
                        value={password}
                        setValue={setPassword}
                    />
                }

                <div className="w-full flex items-center gap-3 justify-evenly">

                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                        setName("")
                        setEmail("")
                        setPassword("")
                        setRoleId("")
                    }} addClass='bg-red-500 w-full text-white '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full text-white'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UserUpdate
import CustomAccording from "../../../customs/according"
import LinkSidebar from "../../../links/link_sidebar"

const SidebarKeuangan = () => {
    return (
        <>
            <LinkSidebar link="/dashboard" name="Dashboard" />
            <LinkSidebar link="/dashboard/unit" name="Unit / Kavling" />
            <LinkSidebar link="/dashboard/selling" name="Penjualan" />
            <LinkSidebar link="/dashboard/consument" name="Konsumen" />
            <CustomAccording name="Logistik" link="/dashboard/logistic" height="">
                <LinkSidebar link="/dashboard/logistic/suplier" name="Data Suplier" />
                <LinkSidebar link="/dashboard/logistic/material" name="Monitoring Barang" />
                <LinkSidebar link="/dashboard/logistic/procurement" name="Pengadaan Barang" />
                <LinkSidebar link="/dashboard/logistic/used-material" name="Penggunaan Barang" />
            </CustomAccording>
            <LinkSidebar link="/dashboard/crediture" name="Kreditur / Debitur" />
            <LinkSidebar link="/dashboard/financial" name="Keuangan" />
            <LinkSidebar link="/dashboard/approved" name="Approved" />
        </>
    )
}

export default SidebarKeuangan


import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import BtnBack from "../../../components/buttons/btn_back"
import CustomHeader from "../../../components/customs/header"
import { useNavigate } from "react-router-dom"
import { getProfile } from "../../../services/user_management/profile_service"
import { useState, useEffect } from "react"
import { BaseUrl } from "../../../services/base/base_url"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear, faUser } from "@fortawesome/free-solid-svg-icons"
import BtnDefault from './../../../components/buttons/btn_default';
import ProfileUpdate from "./update_profile"
import CustomAlert from './../../../components/customs/alert';
import UpdatePassword from "./update_password"

const Setting = () => {
    const [accessToken, setAccessToken] = useState(null)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openModalUpdate, setOpenModalUpdate] = useState(false)
    const [openModalUpdatePassword, setOpenModalUpdatePassword] = useState(false)

    const [profile, setProfile] = useState(null)
    const fetchData = async (accessToken) => {
        setLoading(true)
        try {
            const response = await getProfile({ accessToken })
            if (response.status === 200) {
                setProfile(response.data.data)
            }
        } catch (error) {
            console.log("_____", error)
        }
        setLoading(false)
    }

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token-terra_app");
        if (accessToken) {
            setAccessToken(accessToken)
            fetchData(accessToken)
        } else {
            navigate("/login")
        }
    }, [])

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken)
            setRefresh(false)
        }
    }, [refresh])

    const CustomCard = ({ title, value }) => {
        return (
            <div className="flex flex-col gap-3">
                <p className="text-sm font-medium">{title}</p>
                <p className="text-sm px-3 py-2 border-2 rounded-md">{value}</p>
            </div>
        )
    }

    const [alert, setAlert] = useState(false)
    const [alertType, setAlertType] = useState("")
    const [alertMessage, setAlertMessage] = useState("")

    return (
        <>
            <Helmet>
                <title>Pengaturan</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack />
                    <p className="text-center uppercase">Pengaturan</p>
                </CustomHeader>

                <div className="flex flex-col md:flex-row gap-3 w-full">
                    <div className="flex  flex-col gap-3 p-3 w-fit mx-auto">
                        <div className="w-48 aspect-square mx-auto">
                            {profile?.image ?
                                <img src={BaseUrl + "/storage/" + profile?.image} alt="profile" className="w-full h-full rounded-full shadow-md border" /> :
                                <div className="flex items-center justify-center w-full h-full rounded-full bg-gray-200 shadow-md border">
                                    <FontAwesomeIcon icon={faUser} className="w-16 h-16" />
                                </div>
                            }
                        </div>
                        <BtnDefault handleClick={() => setOpenModalUpdate(true)}>
                            <FontAwesomeIcon icon={faGear} />
                            <p>Update Profil</p>
                        </BtnDefault>
                    </div>
                    <div className="flex flex-col gap-3 p-3 w-full">
                        <CustomCard title="Nama Lengkap" value={<span className="capitalize">{profile?.name}</span>} />
                        <CustomCard title="Email" value={profile?.email} />
                        <CustomCard title="No. HP" value={profile?.phone ?? "-"} />
                    </div>

                </div>

                <div className="flex px-3">
                    <BtnDefault addClass="w-full" handleClick={() => setOpenModalUpdatePassword(true)}>
                        <FontAwesomeIcon icon={faGear} />
                        <p>Ganti Password</p>
                    </BtnDefault>
                </div>


                <ProfileUpdate
                    accessToken={accessToken}
                    open={openModalUpdate}
                    setOpen={setOpenModalUpdate}
                    setRefresh={setRefresh}
                    data={profile}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                />

                <CustomAlert
                    open={alert}
                    setOpen={setAlert}
                    type={alertType}
                    message={alertMessage}
                />

                <UpdatePassword
                    open={openModalUpdatePassword}
                    setOpen={setOpenModalUpdatePassword}
                    accessToken={accessToken}
                    setAlert={setAlert}
                    setAlertType={setAlertType}
                    setAlertMessage={setAlertMessage}
                />
            </LayoutDashboard >
        </>
    )
}

export default Setting
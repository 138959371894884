import { useState } from "react"
import BtnDefault from "../../../../components/buttons/btn_default"
import CustomSpinner from "../../../../components/customs/spinner"
import LayoutModal from "../../../../components/layouts/layout_modal"
import { getAccountBallances, getAccountBeban, getAccountEkuitas, getAccountPasiva, getAccountPendapatan } from "../../../../services/finance/account_service"
import { useEffect } from "react"
import TextAreaDefault from "../../../../components/form/text_area_default"
import InputDefault from "../../../../components/form/input_default"
import SelectFilter from "../../../../components/form/select_filter"
import SelectDefault from "../../../../components/form/select_default"
import { postTransaction } from "../../../../services/finance/transaction_service"
import { getDebiturCreditur } from "../../../../services/user_management/debitur_creditur_service"
import SelectFilterWithPaginate from "../../../../components/form/select_filter_paginate"
import { getUnits } from "../../../../services/unit/unit_service"
import { getConsument } from "../../../../services/user_management/consument_service"
import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useNavigate } from "react-router-dom"
import CustomCurrency from "../../../../components/customs/currency"

const TransactionCreate = () => {

    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [loadingAccountBallance, setLoadingAccountBallance] = useState(false)
    const [loadingAccountPendapatan, setLoadingAccountPendapatan] = useState(false)
    const [loadingAccountBeban, setLoadingAccountBeban] = useState(false)
    const [loadingDebiturCreditur, setLoadingDebiturCreditur] = useState(false)
    const [loadingAccountPasiva, setLoadingAccountPasiva] = useState(false)
    const [loadingAccountEkuitas, setLoadingAccountEkuitas] = useState(false)

    const [category, setCategory] = useState("pemasukan")
    const [accountToId, setAccountToId] = useState("")
    const [accountFromId, setAccountFromId] = useState("")
    const [debiturCrediturId, setDebiturCrediturId] = useState("")
    const [consumentId, setConsumentId] = useState("")
    const [unitId, setUnitId] = useState("")
    const [nominal, setNominal] = useState("0")
    const [description, setDescription] = useState("")
    const [date, setDate] = useState("")

    const [errorCategory, setErrorCategory] = useState(null)
    const [errorAccountFromId, setErrorAccountFromId] = useState(null)
    const [errorAccountToId, setErrorAccountToId] = useState(null)
    const [errorDebiturCrediturId, setErrorDebiturCrediturId] = useState(null)
    const [errorNominal, setErrorNominal] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)
    const [errorDate, setErrorDate] = useState(null)

    const [dataAccountPendapatan, setDataAccountPendapatan] = useState([])
    const fetchDataAccountPendapatan = async (accessToken) => {
        setLoadingAccountPendapatan(true)
        try {
            const response = await getAccountPendapatan({ accessToken });
            if (response.status === 200) {
                setDataAccountPendapatan(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountPendapatan(false)
    };

    const [dataAccountBeban, setDataAccountBeban] = useState([])
    const fetchDataAccountBeban = async (accessToken) => {
        setLoadingAccountBeban(true)
        try {
            const response = await getAccountBeban({ accessToken });
            if (response.status === 200) {
                setDataAccountBeban(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBeban(false)
    };

    const [dataAccountBallance, setDataAccountBallance] = useState([])
    const fetchDataAccountBallance = async (accessToken) => {
        setLoadingAccountBallance(true)
        try {
            const response = await getAccountBallances({ accessToken });
            if (response.status === 200) {
                setDataAccountBallance(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBallance(false)
    };

    const [dataAccountPasiva, setDataAccountPasiva] = useState([])
    const fetchDataAccountPasiva = async (accessToken) => {
        setLoadingAccountPasiva(true)
        try {
            const response = await getAccountPasiva({ accessToken });
            if (response.status === 200) {
                setDataAccountPasiva(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountPasiva(false)
    };

    const [dataAccountEkuitas, setDataAccountEkuitas] = useState([])
    const fetchDataAccountEkuitas = async (accessToken) => {
        setLoadingAccountEkuitas(true)
        try {
            const response = await getAccountEkuitas({ accessToken });
            if (response.status === 200) {
                setDataAccountEkuitas(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountEkuitas(false)
    };

    const [dataDebiturCreditur, setDataDebiturCreditur] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [keyword, setKeyword] = useState("")

    const fetchDataDebiturCreditur = async (accessToken, keyword, page) => {
        setLoadingDebiturCreditur(true)
        try {
            const response = await getDebiturCreditur(accessToken, "", keyword, page,);
            if (response.status === 200) {
                setDataDebiturCreditur(response.data.data);
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingDebiturCreditur(false)
    };

    const [dataConsument, setDataConsument] = useState([])
    const [currentPageConsument, setCurrentPageConsument] = useState(1)
    const [lastPageConsument, setLastPageConsument] = useState(1)
    const [keywordConsument, setKeywordConsument] = useState("")
    const [loadingConsument, setLoadingConsument] = useState(false)

    const fetchDataConsument = async (keywordConsument, currentPageConsument) => {
        setLoadingConsument(true)
        try {
            const response = await getConsument(accessToken, keywordConsument, currentPageConsument)
            if (response.status === 200) {
                setDataConsument(response.data.data)
                setCurrentPageConsument(response.data.meta.current_page)
                setLastPageConsument(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingConsument(false)
    }

    const [dataUnit, setDataUnit] = useState([])
    const [currentPageUnit, setCurrentPageUnit] = useState(1)
    const [lastPageUnit, setLastPageUnit] = useState(1)
    const [keywordUnit, setKeywordUnit] = useState("")
    const [loadingUnit, setLoadingUnit] = useState(false)

    const fetchDataUnit = async (keywordUnit, currentPageUnit) => {
        setLoadingUnit(true)
        try {
            const params = {
                keyword: keywordUnit,
                page: currentPageUnit
            }
            const response = await getUnits(accessToken, params)
            if (response.status === 200) {
                setDataUnit(response.data.data)
                setCurrentPageUnit(response.data.meta.current_page)
                setLastPageUnit(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingUnit(false)
    }

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token-terra_app")
        const userRole = localStorage.getItem("user_role-terra_app")
        if (accessToken && userRole) {
            if (["super admin", "keuangan"].includes(userRole)) {
                setAccessToken(accessToken)
                setUserRole(userRole)
                fetchDataAccountBallance(accessToken)
                fetchDataAccountPendapatan(accessToken)
                fetchDataAccountBeban(accessToken)
                fetchDataDebiturCreditur(accessToken, keyword, currentPage)
                fetchDataConsument(keywordConsument, currentPageConsument)
                fetchDataUnit(keywordUnit, currentPageUnit)
                fetchDataAccountPasiva(accessToken)
                fetchDataAccountEkuitas(accessToken)
            } else {
                navigate("/403")
            }
        } else {
            navigate("/login")
        }
    }, [])

    const handleChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setNominal(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const dataOptionCategory = [
        { 'name': 'pemasukan' },
        { 'name': 'pengeluaran' },
        { 'name': 'pengalihan asset' },
        // { 'name': 'penyesuaian asset' },
        { 'name': 'tambah modal' },
        { 'name': 'tarik modal' },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorCategory(null)
            setErrorAccountFromId(null)
            setErrorAccountToId(null)
            setErrorNominal(null)
            setErrorDescription(null)
            setErrorDate(null)

            if (!category) {
                setErrorCategory("Jenis transaksi harus diisi")
            } else if (!accountFromId) {
                setErrorAccountFromId("Peruntukan harus diisi")
            } else if (!accountToId) {
                setErrorAccountToId("Kas / Bank harus diisi")
            } else if (!nominal) {
                setErrorNominal("Nominal harus diisi")
            } else if (!description) {
                setErrorDescription("Keterangan harus diisi")
            } else if (!date) {
                setErrorDate("Tanggal transaksi harus diisi")
            } else {
                const formData = new FormData()
                formData.append("category", category)
                formData.append("account_from_id", accountFromId)
                formData.append("account_to_id", accountToId)
                formData.append("nominal", nominal)
                formData.append("description", description)
                formData.append("date", date)

                const response = await postTransaction(accessToken, formData)
                if (response.status === 201) {
                    navigate("/dashboard/financial/transaction")
                } else {
                    alert("Transaksi gagal disimpan..!")
                }
            }

        } catch (error) {
            alert("Transaksi gagal disimpan..!")
        }
        setLoading(false)
    }
    return (
        <>
            <Helmet>
                <title>Transaksi Baru</title>
            </Helmet>
            <LayoutDashboard >
                <form onSubmit={handleSubmit}>
                    <CustomHeader>
                        <BtnBack />
                        <p className="text-xl font-bold">Transaksi Baru</p>
                        <div className="flex items-center justify-end gap-3">
                            <BtnDefault type="submit">
                                {loading === true ? <CustomSpinner addClass='' /> : <>
                                    <FontAwesomeIcon icon={faSave} />
                                    <p>Simpan</p>
                                </>}
                            </BtnDefault>
                        </div>
                    </CustomHeader>
                    <div className="flex flex-col gap-3 p-3 w-full">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-3 w-full">
                            <div className="flex flex-col gap-3">
                                <SelectDefault
                                    label='Jenis Transaksi'
                                    options={dataOptionCategory.map((item) => ({ value: item?.name, label: item?.name }))}
                                    onSelect={(e) => {
                                        setCategory(e)
                                        if (["tambah modal", "tarik modal"].includes(e)) {
                                            setAccountFromId("2-200")
                                        } else {
                                            setAccountFromId("")
                                        }
                                    }}
                                    errorMessage={errorCategory}
                                />

                                {!["tambah modal", "tarik modal"].includes(category) &&
                                    <SelectFilter
                                        label="Peruntukan"
                                        placeholder='Pilih peruntukan'
                                        options={
                                            category === "pengalihan asset" ? 
                                            dataAccountBallance.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` })) :
                                            category === "pemasukan" ?
                                                dataAccountPendapatan.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` })) :
                                                category === "tambah modal" ?
                                                    dataAccountEkuitas.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` })) :
                                                    dataAccountBeban.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))
                                        }
                                        value={accountFromId}
                                        onSelect={(e) => setAccountFromId(e)}
                                        errorMessage={errorAccountFromId}
                                        loading={loadingAccountPendapatan}
                                    />
                                }

                                <SelectFilter
                                    label={["pemasukan", "tambah modal"].includes(category) ? "Masuk ke" : "Diambil dari"}
                                    placeholder='Pilih kas / bank'
                                    options={dataAccountBallance.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))}
                                    value={accountToId}
                                    onSelect={(e) => setAccountToId(e)}
                                    errorMessage={errorAccountToId}
                                    loading={loadingAccountBallance}
                                />

                                {["pengeluaran", "tarik modal"].includes(category) &&
                                    <div className='flex items-center justify-between w-full text-xs md:text-sm'>
                                        <div className='font-semibold w-[100px]'>Saldo Akun :</div>
                                        <div className='px-3 py-2 border-2 rounded-full w-full text-right'>
                                            <CustomCurrency value={dataAccountBallance.find(account => account.id === accountToId)?.ballance ?? 0} />
                                        </div>
                                    </div>
                                }

                                <InputDefault
                                    id="nominal"
                                    type='text'
                                    label="Nominal"
                                    value={formatCurrency(nominal)}
                                    handleChange={handleChange}
                                    errorMessage={errorNominal ?? undefined}
                                    addClass="text-right"
                                    inputMode="numeric"
                                />
                            </div>

                            <div className="flex flex-col gap-3">
                                <SelectFilterWithPaginate
                                    label='Debitur'
                                    placeholder='Pilih debitur'
                                    options={dataDebiturCreditur.map(item => ({ value: item?.id, label: `${item?.name} (${item?.id})` }))}
                                    onSelect={(e) => setDebiturCrediturId(e)}
                                    currentPage={currentPage}
                                    lastPage={lastPage}
                                    setCurrentPage={setCurrentPage}
                                    keyword={keyword}
                                    setKeyword={setKeyword}
                                    handleSearch={(e) => {
                                        setKeyword(e.target.value)
                                        fetchDataDebiturCreditur(accessToken, e.target.value, currentPage)
                                    }}
                                    loading={loadingDebiturCreditur}
                                    errorMessage={errorDebiturCrediturId}
                                    value={debiturCrediturId}
                                    plusOption={
                                        <button
                                            type="button"
                                            onClick={() => setDebiturCrediturId("")}
                                            className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                            Pilih
                                        </button>
                                    }
                                />

                                <SelectFilterWithPaginate
                                    label="Nama Konsumen"
                                    placeholder="Pilih konsumen"
                                    options={dataConsument.map(item => ({ value: item?.id, label: `${item?.name}` }))}
                                    value={consumentId}
                                    onSelect={(e) => setConsumentId(e)}
                                    currentPage={currentPageConsument}
                                    lastPage={lastPageConsument}
                                    setCurrentPage={setCurrentPageConsument}
                                    keyword={keywordConsument}
                                    setKeyword={setKeywordConsument}
                                    loading={loadingConsument}
                                    plusOption={
                                        <button
                                            type="button"
                                            onClick={() => setConsumentId("")}
                                            className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                            Pilih
                                        </button>
                                    }
                                />

                                <SelectFilterWithPaginate
                                    label="Nama Unit"
                                    placeholder="Pilih unit"
                                    options={dataUnit.map(item => ({ value: item?.id, label: `${item?.name}` }))}
                                    value={unitId}
                                    onSelect={(e) => setUnitId(e)}
                                    currentPage={currentPageUnit}
                                    lastPage={lastPageUnit}
                                    setCurrentPage={setCurrentPageUnit}
                                    keyword={keywordUnit}
                                    setKeyword={setKeywordUnit}
                                    loading={loadingUnit}
                                    plusOption={
                                        <button
                                            type="button"
                                            onClick={() => setUnitId("")}
                                            className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                            Pilih
                                        </button>
                                    }
                                />

                                <InputDefault
                                    id="date"
                                    type='date'
                                    label="Tanggal Transaksi"
                                    value={date}
                                    setValue={setDate}
                                    errorMessage={errorDate}
                                />
                            </div>
                        </div>

                        <TextAreaDefault
                            id="note"
                            label="Keterangan"
                            value={description}
                            setValue={setDescription}
                            errorMessage={errorDescription}
                        />
                    </div>
                </form>
            </LayoutDashboard>
        </>
    )
}

export default TransactionCreate

import LayoutModal from './../../../components/layouts/layout_modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import InputDefault from './../../../components/form/input_default';
import { useEffect, useState } from 'react';
import BtnDefault from '../../../components/buttons/btn_default';
import CustomSpinner from '../../../components/customs/spinner';
import { getUserRole } from '../../../services/user_management/user_role_service';
import { postUser } from '../../../services/user_management/user_service';
import SelectDefault from './../../../components/form/select_default';
import InputPassword from '../../../components/form/input_password';
const UserCreate = ({
    accessToken, open, setOpen, setRefresh,
    setOpenAlert, setMessageAlert, setTypeAlert
}) => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [roleId, setRoleId] = useState("");

    const [errorName, setErrorName] = useState(null);
    const [errorEmail, setErrorEmail] = useState(null);
    const [errorPassword, setErrorPassword] = useState(null);
    const [errorRole, setErrorRole] = useState(null);

    const [loading, setLoading] = useState(false);


    const [userRole, setUserRole] = useState([]);
    const fetchUserRole = async () => {
        setLoading(true);
        try {
            const response = await getUserRole(accessToken);
            if (response.status === 200) {
                setUserRole(response.data.data);
            }
        } catch (error) {
            console.log("__");
        }
        setLoading(false);
    }
    useEffect(() => {
        fetchUserRole();
    }, [accessToken])

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            setErrorName(null);
            setErrorEmail(null);
            setErrorPassword(null);
            setErrorRole(null);

            if (name === "") {
                setErrorName("Nama tidak boleh kosong");
            } else if (email === "") {
                setErrorEmail("Email tidak boleh kosong");
            } else if (!email.includes("@gmail.com")) {
                setErrorEmail("Email tidak valid..!");
            } else if (password === "") {
                setErrorPassword("Password tidak boleh kosong");
            } else if (roleId === "") {
                setErrorRole("Role tidak boleh kosong");
            } else {

                const data = new FormData();
                data.append("name", name);
                data.append("email", email.toLowerCase());
                data.append("password", password);
                data.append("role_id", roleId);

                const response = await postUser(accessToken, data);
                if (response.status === 201) {
                    setOpenAlert(true);
                    setMessageAlert(response.data.message);
                    setTypeAlert("success");
                    setOpen(false);
                    setRefresh(true);
                }
            }
        } catch (error) {
            setOpenAlert(true);
            setMessageAlert(error.response.data.message);
            setTypeAlert("error");
        }
        setLoading(false);
    }

    return (
        <LayoutModal name={"Tambah Pengguna"} open={open} setOpen={setOpen}>
            <form onSubmit={handleSubmit}>
                <div className='flex flex-col gap-3 pb-5'>
                    <InputDefault
                        label={"Nama"}
                        placeholder="Masukan nama lengkap"
                        value={name} setValue={setName}
                        errorMessage={errorName}
                    />

                    <InputDefault
                        label={"Email"}
                        placeholder="Masukan email"
                        value={email} setValue={setEmail}
                        errorMessage={errorEmail}
                        addClass="lowercase"
                        inputMode="email"
                    />

                    <InputPassword
                        label={"Password"}
                        placeholder="Masukan password"
                        value={password} setValue={setPassword}
                        errorMessage={errorPassword}
                    />

                    <SelectDefault
                        label={"Role"} value={roleId}
                        options={userRole.map((item) => ({
                            label: item.name,
                            value: item.id
                        }))}
                        onSelect={(e) => setRoleId(e)}
                        errorMessage={errorRole}
                    />
                </div>
                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => setOpen(false)} addClass='bg-red-500 w-full text-white'>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full text-white'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    );
};

export default UserCreate;

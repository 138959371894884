import { Helmet } from 'react-helmet';
import LayoutDashboard from '../../../../components/layouts/layout_dashboard';
import CustomHeader from '../../../../components/customs/header';
import BtnBack from '../../../../components/buttons/btn_back';
import BtnDefault from '../../../../components/buttons/btn_default';
import { exportTransaction, getTransaction } from '../../../../services/finance/transaction_service';
import CustomCurrency from '../../../../components/customs/currency';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilter, faPlus, faSearch, faTrash } from '@fortawesome/free-solid-svg-icons';
import CustomPaginate from '../../../../components/customs/paginate';
import CustomAlert from '../../../../components/customs/alert';
import InputSearch from '../../../../components/form/input_search';
import CustomSpinner from '../../../../components/customs/spinner';
import FormatDateV2 from '../../../../components/format/date_v2';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import TransactionUpdate from './update';
import CustomConfirm from '../../../../components/customs/confirm';
import SubmitUpdate from './function_update';
import FilterExportJurnal from '../jurnal-umum/filter_export';
const Transaction = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");

    const [data, setData] = useState([])
    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    let [from, setFrom] = useState(1)
    const [keyword, setKeyword] = useState("")
    const fetchData = async (accessToken, keyword, currentPage) => {
        setLoading(true)
        try {
            const params = {
                category: category,
                year: year,
                periode: periode,
                keyword: keyword,
                page: currentPage
            }

            const response = await getTransaction(accessToken, params)
            if (response.status === 200) {
                setDebit(response.data.debit)
                setCredit(response.data.credit)
                setData(response.data.data);
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
                setFrom(response.data.meta.from);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, keyword, currentPage)
            setRefresh(false)
        }
    }, [refresh])

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')

        if (getAccessToken && getUserRole) {
            if (["super admin", "admin", "keuangan"].includes(getUserRole)) {
                setAccessToken(getAccessToken)
                setUserRole(getUserRole)
                fetchData(getAccessToken, keyword, currentPage)
            } else {
                navigate("/403")
            }
        } else {
            navigate("/login")
        }
    }, [])

    const MyTr = ({ name, value }) => {
        return (
            <tr>
                <td className="font-semibold capitalize">{name}</td>
                <td className="font-semibold px-2">:</td>
                <td>{value}</td>
            </tr>
        )
    }

    const [category, setCategory] = useState("0")
    const [year, setYear] = useState(new Date().getFullYear());
    let currentYear = new Date().getFullYear()
    const years = Array.from({ length: 20 }).map((_, index) => ({
        value: (currentYear - 10 + index).toString(),
        label: (currentYear - 10 + index).toString()
    }));
    const [periode, setPeriode] = useState("1")

    const [openFilter, setOpenFilter] = useState(false)
    const [filter, setFilter] = useState(false)
    useEffect(() => {
        if (filter) {
            fetchData(accessToken, keyword, currentPage)
        }
        setFilter(false)
    }, [filter])

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")
    const [typeAlert, setTypeAlert] = useState("")

    const [loadingExport, setLoadingExport] = useState(false)
    const handleExport = async () => {
        setLoadingExport(true)
        try {
            const params = {
                category: category,
                year: year,
                periode: periode
            }
            const response = await exportTransaction(accessToken, params)
            if (response.status === 200) {
                const generateRandomString = (length) => {
                    return Math.random().toString(36).substring(2, 2 + length);
                };
                const uniqid = Date.now() + '-' + generateRandomString(5);
                const fileName = `transaksi_${uniqid}.xlsx`;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingExport(false)
    }


    const [openUpdate, setOpenUpdate] = useState(false)
    const [dataUpdate, setDataUpdate] = useState(null)
    const [deletedId, setDeletedId] = useState("")

    const [loadingDelete, setLoadingDelete] = useState(false)

    const [openConfirm, setOpenConfirm] = useState(false)
    const [categoryUpdate, setCategoryUpdate] = useState(false)

    return (
        <>
            <Helmet>
                <title>Data Transaksi</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack link="/dashboard/financial" />
                    <p className="text-center">Data Transaksi</p>
                    <div className="flex items-center justify-end gap-2 text-sm">
                        <BtnDefault handleClick={handleExport}>
                            {loadingExport ? <CustomSpinner /> :
                                <>
                                    <FontAwesomeIcon icon={faFileExcel} />
                                    <p className='hidden md:flex'>Export</p>
                                </>
                            }
                        </BtnDefault>
                        <BtnDefault
                            type="button"
                            handleClick={() => setOpenFilter(true)}
                        >
                            <FontAwesomeIcon icon={faFilter} />
                            <p className='hidden md:flex'>Filter</p>
                        </BtnDefault>
                        {["super admin", "keuangan"].includes(userRole) &&
                            <BtnDefault handleClick={() => navigate("/dashboard/financial/transaction/create")}>
                                <FontAwesomeIcon icon={faPlus} />
                                <p className='hidden md:flex'>Transaksi</p>
                            </BtnDefault>
                        }
                    </div>
                </CustomHeader>

                <div className="w-full flex flex-col md:flex-row items-start justify-between px-3 py-2 sticky top-16 left-0 bg-white z-10 gap-3" >
                    <div>
                        <MyTr name="Total Pemasukan"
                            value={
                                <CustomCurrency value={debit} />
                            }
                        />
                        <MyTr name="Total Pengeuaran"
                            value={
                                <CustomCurrency value={credit} />
                            }
                        />
                        <MyTr name="Sisa Saldo"
                            value={
                                <p className="font-semibold"><CustomCurrency value={debit - credit} /></p>
                            }
                        />
                    </div>

                    <div className='w-full md:max-w-[300px]'>
                        Periode : {!year ? "semua" : `${day}-${month}-${year}`}
                        <form className='flex items-center relative gap-2 w-full' onSubmit={(e) => {
                            e.preventDefault()
                            setCurrentPage(1)
                            fetchData(accessToken, keyword, 1)
                        }}>
                            <InputSearch
                                placeholder='Cari data transaksi'
                                value={keyword}
                                handleSearch={(e) => {
                                    setKeyword(e.target.value)
                                }}
                                addClass="w-full"
                            />
                            <button type='submit' className=' absolute right-[2px] bg-custom-dark-yellow text-custom-navy px-3 py-2 md:py-[2px] rounded-r-full'>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </form>
                    </div>
                </div>

                <div className='w-full px-3 text-xs md:text-sm'>
                    <table className={`max-w-screen-2xl ${data?.length === 0 || loading === true ? 'min-w-max md:min-w-full' : 'min-w-max '}`}>
                        <thead className="bg-custom-dark-yellow text-custom-navy sticky top-[198px]  md:top-[158px] ">
                            <tr className="">
                                <th className="px-3 py-2 rounded-tl-xl" >No</th>
                                <th className="px-3 py-2" colSpan={2}>Aksi</th>
                                <th className="px-3 py-2" >Tanggal</th>
                                <th className="px-3 py-2" >Kode Transaksi</th>
                                <th className="px-3 py-2" >Kategori Transaksi</th>
                                <th className="px-3 py-2" >Jenis Peruntukan</th>
                                <th className="px-3 py-2" >Nominal</th>
                                <th className="px-3 py-2 rounded-tr-xl">Keterangan</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? "Loading..!" :
                                    data?.length === 0 ?
                                        <tr className="">
                                            <td className="px-3 py-2" colSpan={20}>
                                                Tidak ada transaksi..!
                                            </td>
                                        </tr> :
                                        data?.length === 0 ? "Tidak ada data..!" :
                                            data.map((item, index) => (
                                                <>
                                                    <tr className={`${index % 2 === 1 ? 'bg-gray-200' : ''} hover:bg-gray-400 transition-colors`}>
                                                        <td className="px-3 py-2  text-center" >{from++}</td>
                                                        <td className="px-3 py-2  text-center" >
                                                            <button
                                                                type='button'
                                                                onClick={() => {
                                                                    setDeletedId(item?.id)
                                                                    setDataUpdate(item)
                                                                    setCategoryUpdate("delete")
                                                                    // setOpenConfirm(true)
                                                                    setOpenUpdate(true)
                                                                }}
                                                                className='text-white bg-red-500 rounded-md px-3 py-2 hover:bg-red-600 transition-colors'
                                                            >
                                                                <FontAwesomeIcon icon={faTrash} />
                                                            </button>
                                                        </td>
                                                        <td className="px-3 py-2  text-center" >
                                                            <button
                                                                type='button'
                                                                onClick={() => {
                                                                    setDataUpdate(item)
                                                                    setCategoryUpdate("update")
                                                                    setOpenUpdate(true)
                                                                }}
                                                                className='text-white bg-yellow-500 rounded-md px-3 py-2 hover:bg-yellow-600 transition-colors'
                                                            >
                                                                <FontAwesomeIcon icon={faEdit} />
                                                            </button>
                                                        </td>
                                                        <td className="px-3 py-2  " >
                                                            <FormatDateV2 date={item?.date} />
                                                        </td>
                                                        <td className="px-3 py-2  ">
                                                            {item?.code}
                                                        </td>
                                                        <td className="px-3 py-2  ">
                                                            {item?.category?.name}
                                                        </td>
                                                        <td className="px-3 py-2  ">
                                                            {item?.account_from?.id}  -
                                                            {item?.account_from?.name}
                                                        </td>
                                                        <td className="px-3 py-2  text-end">
                                                            <CustomCurrency value={item?.nominal} />
                                                        </td>
                                                        <td className="px-3 py-2  max-w-[500px]">
                                                            {item?.description}
                                                        </td>
                                                    </tr>
                                                </>
                                            ))}

                            {lastPage < 2 && (
                                <tr>
                                    <td colSpan={20} className='py-4 bg-custom-dark-yellow' />
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>

                {lastPage > 1 &&
                    <div className="flex items-center justify-center md:justify-end w-full bg-custom-dark-yellow px-3 py-1 sticky bottom-0 left-0">
                        <CustomPaginate
                            currentPage={currentPage} lastPage={lastPage}
                            handlePaginate={(e) => {
                                setCurrentPage(e)
                                fetchData(accessToken, keyword, e)
                            }} />
                    </div>
                }


                <CustomAlert
                    open={openAlert}
                    setOpen={setOpenAlert}
                    message={messageAlert}
                    type={typeAlert}
                />

                <CustomConfirm
                    handleConfirm={(e) => {
                        const paramData = {
                            "id": deletedId,
                            "category": "delete"
                        }

                        SubmitUpdate(
                            e,
                            accessToken,
                            paramData,
                            loadingDelete,
                            setLoadingDelete,
                            false,
                            null,
                            setOpenAlert,
                            setMessageAlert,
                            setTypeAlert
                        )
                    }}
                    message={"Apakah Anda yakin akan melakukan permintaan hapus data..?"}
                    open={openConfirm}
                    setOpen={setOpenConfirm}
                />


                <FilterExportJurnal
                    isOpen={openFilter}
                    setIsOpen={setOpenFilter}
                    category={category} setCategory={setCategory}
                    year={year} setYear={setYear}
                    periode={periode} setPeriode={setPeriode}
                    setFilter={setFilter}
                />

                <TransactionUpdate
                    accessToken={accessToken}
                    open={openUpdate} setOpen={setOpenUpdate}
                    setRefresh={setRefresh}
                    data={dataUpdate}
                    setOpenAlert={setOpenAlert} setMessageAlert={setMessageAlert} setTypeAlert={setTypeAlert}
                    category={categoryUpdate}
                />

            </LayoutDashboard>
        </>
    )
}

export default Transaction
import { useState } from "react";
import BtnDefault from "../../../../../components/buttons/btn_default";
import InputDefault from "../../../../../components/form/input_default";
import LayoutModal from "../../../../../components/layouts/layout_modal";
import { updateMaterial } from "../../../../../services/logistic/material_service";
import CustomSpinner from "../../../../../components/customs/spinner";

const ProcurementUpdateReadyStock = ({ isOpen, setIsOpen, setRefresh, accessToken, materialId, readyStock, unit }) => {

    const [loading, setLoading] = useState(false)

    const [quantity, setQuantity] = useState(0);
    const [errorQuantity, setErrorQuantity] = useState(null)

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {

            setErrorQuantity(null)
            if (quantity < 0.1 || quantity === '' || quantity === null) {
                setErrorQuantity('Silahkan masukkan jumlah..!')
            } else if (quantity > readyStock) {
                setErrorQuantity('Jumlah tidak boleh lebih dari barang yang belum datang..!')
            } else {

                const formData = {
                    'quantity': quantity
                }

                const response = await updateMaterial(accessToken, formData, materialId)
                if (response.status === 200) {
                    setIsOpen && setIsOpen(false)
                    setRefresh && setRefresh(true)
                } else {
                    alert('gagal menyimpan data..!')
                }
            }

        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
        setLoading(false)
    }




    return (
        <LayoutModal open={isOpen} setOpen={setIsOpen} name={'Update Barang yang Datang'}>
            <form onSubmit={handleSubmit} className='h-full flex flex-col gap-3 w-full overflow-auto'>
                <div className='flex flex-col text-xs md:text-sm'>
                    <label htmlFor="" className='font-semibold'>Barang Yang belum Datang({unit})</label>
                    <div className='px-3 py-2 border-2 rounded-full w-full text-center'>
                        {readyStock}
                    </div>
                </div>

                <InputDefault
                    id="quantity"
                    type='number'
                    label={`Jumlah Barang yang datang (${unit})`}
                    placeholder={`Masukkan Jumlah Barang yang datang`}
                    value={quantity}
                    handleChange={(e) => {
                        let value = e.target.value;
                        const numericValue = parseFloat(value);

                        if (!isNaN(numericValue) && numericValue >= 0.1) {
                            if (value <= readyStock) {
                                setQuantity(numericValue)
                            } else if (numericValue > readyStock) {
                                setQuantity(readyStock)
                            }
                        }
                    }}
                    addClass="text-center"
                    errorMessage={errorQuantity}
                />

                <div className="w-full flex items-center gap-3 justify-evenly text-xs md:text-sm">
                    <BtnDefault type="button" handleClick={() => setIsOpen && setIsOpen(false)} addClass='bg-red-500 w-full text-white'>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full text-white'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Update"}
                    </BtnDefault>
                </div>
            </form>

        </LayoutModal>
    )
}

export default ProcurementUpdateReadyStock

import LayoutModal from './../../../../components/layouts/layout_modal';
import InputDefault from './../../../../components/form/input_default';
import BtnDefault from '../../../../components/buttons/btn_default';
import { useState } from 'react';
import { postSuplier } from '../../../../services/user_management/suplier_service';
import CustomSpinner from '../../../../components/customs/spinner';

const SuplierCreate = ({ isOpen, setIsOpen, setRefresh, accessToken }) => {
    const [loading, setLoading] = useState(false)

    const [name, setName] = useState('');
    const [errorName, setErrorName] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
            setErrorName('');

            if (name.trim() === '') {
                setErrorName('Nama suplier wajib diisi..!');
            } else if (name.length < 3) {
                setErrorName('nama suplier minimal memiliki 3 huruf..!');
            } else {
                const formData = new FormData()
                formData.append('name', name)
                const response = await postSuplier(accessToken, formData)
                if (response.status === 201) {
                    setIsOpen && setIsOpen(false)
                    setRefresh && setRefresh(true)
                } else {
                    alert('gagal menyimpan data..!')
                }

            }
        } catch (error) {
            alert('Silahkan periksa koneksi internet Anda..!')
        }
        setLoading(false)
    }

    return (
        <LayoutModal open={isOpen} setOpen={setIsOpen} name='Tambah Suplier'>

            <form onSubmit={handleSubmit} className='flex flex-col gap-3'>
                <InputDefault
                    id="name"
                    type='text'
                    label="Nama Suplier"
                    placeholder='Masukkan nama suplier'
                    value={name}
                    setValue={setName}
                    errorMessage={errorName}
                />

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => setIsOpen && setIsOpen(false)} addClass='bg-red-500 w-full text-white '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full text-white'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>

            </form>
        </LayoutModal>
    )
}

export default SuplierCreate
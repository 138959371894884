import { useEffect, useState } from "react"
import BtnDefault from "../../../../components/buttons/btn_default"
import LayoutModal from "../../../../components/layouts/layout_modal"
import CustomSpinner from "../../../../components/customs/spinner"
import InputDefault from "../../../../components/form/input_default"
import SelectDefault from "../../../../components/form/select_default"
import { getAccountCategory, postAccount } from "../../../../services/finance/account_service"
import SelectFilter from "../../../../components/form/select_filter"

const AccountCreate = ({
    accessToken, userRole,
    open, setOpen, setRefresh,
    setAlert, setAlertType, setAlertMessage
}) => {
    const [loading, setLoading] = useState(false)
    const [loadingCategory, setLoadingCategory] = useState(false)

    const [defaultId, setDefaultId] = useState("1-")
    const [id, setId] = useState("")
    const [name, setName] = useState("")
    const [category, setCategory] = useState("aktiva")
    const [postType, setPostType] = useState("debit")

    const [errorName, setErrorName] = useState(null)
    const [errorId, setErrorId] = useState(null)
    const [errorCategoryId, setErrorCategoryId] = useState(null)
    const [errorPostType, setErrorPostType] = useState(null)

    const [dataCategory, setDataCategory] = useState([])
    const fetchDataCategory = async (accessToken) => {
        setLoadingCategory(true)
        try {
            const response = await getAccountCategory(accessToken)
            if (response.status === 200) {
                setDataCategory(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingCategory(false)
    }

    useEffect(() => {
        fetchDataCategory(accessToken)
    }, [])

    const dataOptionCategory = [
        { 'name': 'aktiva' },
        { 'name': 'fassiva' },
        { 'name': 'penerimaan' },
        { 'name': 'hpp' },
    ]

    const dataOption = [
        { 'name': 'debit' },
        { 'name': 'kredit' },
    ]

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {
            setErrorId(null)
            setErrorName(null)
            setErrorCategoryId(null)
            setErrorPostType(null)

            const formData = new FormData()
            formData.append('id', id.toString())
            formData.append('name', name)
            formData.append('category', category)
            formData.append('post_type', postType)

            const response = await postAccount(accessToken, formData)
            if (response.status === 201) {
                setOpen(false)
                setRefresh(true)
                setAlert(true)
                setAlertType("success")
                setAlertMessage("Akun berhasil ditambahkan..!")
            } else {
                setAlert(true)
                setAlertType("error")
                setAlertMessage("Akun gagal ditambahkan..!")
            }
        } catch (error) {
            setAlert(true)
            setAlertType("error")
            setAlertMessage("Silahkan periksa koneksi internet Anda..!")
        }
        setLoading(false)
    }
    return (
        <LayoutModal name="Tambah Akun Baru" open={open}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">

                <SelectDefault
                    name="Kategori"
                    placeholder='Pilih kategori'
                    options={dataOptionCategory.map((item) => ({ value: item?.name, label: item?.name }))}
                    onSelect={(e) => {
                        setCategory(e)
                        switch (e) {
                            case "aktiva":
                                setDefaultId("1-")
                                break;
                            case "fassiva":
                                setDefaultId("2-")
                                break;
                            case "penerimaan":
                                setDefaultId("3-")
                                break;
                            case "hpp":
                                setDefaultId("4-")
                                break;
                        }
                    }}
                />

                <div className="relative">
                    <div className="absolute bottom-[10px] left-3 font-semibold">
                        {defaultId}
                    </div>
                    <InputDefault
                        id="name"
                        type='number'
                        name="ID Akun"
                        placeholder={"000"}
                        value={id}
                        setValue={setId}
                        errorMessage={errorId}
                        addClass="pl-8"
                    />
                </div>

                <InputDefault
                    id="name"
                    type='text'
                    name="Nama Akun"
                    value={name}
                    setValue={setName}
                    errorMessage={errorName}
                    addClass="uppercase"
                />

                <SelectDefault
                    name='Tipe Post'
                    options={dataOption.map((item) => ({ value: item?.name, label: item?.name }))}
                    onSelect={(e) => setPostType(e)}
                />

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default AccountCreate
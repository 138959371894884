import CustomAccording from "../../../customs/according"
import LinkSidebar from "../../../links/link_sidebar"

const SidebarAdmin = () => {
    return (
        <>
            <LinkSidebar link="/dashboard" name="Dashboard" />
            <LinkSidebar link="/dashboard/user" name="Pengguna" />
            <LinkSidebar link="/dashboard/unit" name="Unit / Kavling" />
            <LinkSidebar link="/dashboard/selling" name="Penjualan" />
            <LinkSidebar link="/dashboard/consument" name="Konsumen" />
            <CustomAccording name="Logistik" link="/dashboard/logistic" height="">
                <LinkSidebar link="/dashboard/logistic/suplier" name="Data Suplier" />
                <LinkSidebar link="/dashboard/logistic/material" name="Monitoring Barang" />
                <LinkSidebar link="/dashboard/logistic/procurement" name="Pengadaan Barang" />
                <LinkSidebar link="/dashboard/logistic/used-material" name="Penggunaan Barang" />
            </CustomAccording>
        </>
    )
}

export default SidebarAdmin


import { Helmet } from 'react-helmet';
import LayoutDashboard from '../../../../components/layouts/layout_dashboard';
import CustomHeader from '../../../../components/customs/header';
import BtnBack from '../../../../components/buttons/btn_back';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { showCashflow } from '../../../../services/finance/cashflow_service';
import { months } from '../../../../components/utils/month_data';
import CustomCurrency from '../../../../components/customs/currency';
import BtnDefault from '../../../../components/buttons/btn_default';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

const CashflowDetail = () => {
    const [accessToken, setAccessToken] = useState(null);
    const [userRole, setUserRole] = useState(null);
    const navigate = useNavigate();
    const { id } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const year = queryParams.get('year');
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState(null);

    const fetchData = async ({ accessToken }) => {
        setLoading(true);
        try {
            const response = await showCashflow({ accessToken, id, year });
            if (response.status === 200) {
                setData(response.data.data);
            } else {
                console.log(response);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app');
        const userRole = localStorage.getItem('user_role-terra_app');

        if (accessToken && userRole) {
            if (["super admin", "admin", "keuangan"].includes(userRole)) {
                fetchData({ accessToken });
            } else {
                navigate('/403');
            }
        } else {
            navigate('/login');
        }
    }, []);

    const MyTr = ({ name, value }) => {
        return (
            <tr>
                <td className='px-3 py-1 capitalize'>{name}</td>
                <td>:</td>
                <td className='px-3 py-1 text-end capitalize'>{value}</td>
            </tr>
        );
    };

    // Variabel untuk menyimpan total
    let totalExpected = 0;
    let totalActual = 0;

    return (
        <>
            <Helmet>
                <title>Detail Arus Kas</title>[]
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack link={"/dashboard/financial/arus-kas"} />
                    <p className='text-center font-semibold'>Detail Arus Kas</p>
                    {/* <div className='flex gap-3 items-center justify-end'>
                        <BtnDefault handleClick={() => navigate(`/dashboard/financial/arus-kas/update/${id}?year=${year}`)}>
                            <FontAwesomeIcon icon={faEdit} />
                            <p>Edit Expected</p>
                        </BtnDefault>
                    </div> */}
                </CustomHeader>
                <div className='p-3 top-16 left-0 bg-white z-10'>
                    <MyTr name="ID Akun" value={data?.id} />
                    <MyTr name="Nama Akun" value={data?.name} />
                    <MyTr name="Kategori" value={data?.category?.name} />
                    <MyTr name="Post" value={data?.post_type} />
                    <MyTr name="Tahun" value={year} />
                </div>

                <div className='w-full px-3 pb-5'>
                    <table className='max-w-screen-2xl min-w-full'>
                        <thead className=''>
                            <tr className='bg-custom-dark-yellow'>
                                <th className='px-3 py-2 rounded-tl-xl'>No</th>
                                <th className='px-3 py-2'>Nama Bulan</th>
                                {/* <th className='px-3 py-2'>Expected</th> */}
                                <th className='px-3 py-2 rounded-tr-xl'>Aktual</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? "Loading..." :
                                    months.map((month, index) => {
                                        const expectedValue = data ? data[month?.name.toLowerCase()]?.expected ?? 0 : 0;
                                        const actualValue = data ? data[month?.name.toLowerCase()]?.actual ?? 0 : 0;

                                        // Menambahkan ke total
                                        totalExpected += expectedValue;
                                        totalActual += actualValue;

                                        return (
                                            <tr key={index} className={`${index % 2 === 1 ? 'bg-gray-200' : ''}`}>
                                                <td className='px-3 py-2'>{index + 1}</td>
                                                <td className='px-3 py-2'>{month?.name}</td>
                                                {/* <td className='px-3 py-2 text-end '>
                                                    <CustomCurrency value={expectedValue} />
                                                </td> */}
                                                <td className='px-3 py-2 text-end'>
                                                    <CustomCurrency value={actualValue} />
                                                </td>
                                            </tr>
                                        );
                                    })
                            }
                            <tr className='bg-custom-dark-yellow font-bold text-lg'>
                                <td className='px-3 py-2 text-end' colSpan={2}>Total</td>
                                {/* <td className='px-3 py-2 text-end'>
                                    <CustomCurrency value={totalExpected} />
                                </td> */}
                                <td className='px-3 py-2 text-end'>
                                    <CustomCurrency value={totalActual} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </LayoutDashboard>
        </>
    );
};

export default CashflowDetail;

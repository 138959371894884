import { useEffect, useState } from "react"
import SelectDefault from "../../../../components/form/select_default"
import LayoutModal from "../../../../components/layouts/layout_modal"
import SelectFilter from "../../../../components/form/select_filter";
import { getAccountBallances } from "../../../../services/finance/account_service";
import TextAreaDefault from "../../../../components/form/text_area_default";
import InputDefault from "../../../../components/form/input_default";
import BtnDefault from "../../../../components/buttons/btn_default";
import CustomSpinner from "../../../../components/customs/spinner";
import { getDebiturCreditur } from "../../../../services/user_management/debitur_creditur_service";
import SelectFilterWithPaginate from "../../../../components/form/select_filter_paginate";
import { postUtang } from "../../../../services/finance/utang_service";
import CustomCurrency from "../../../../components/customs/currency";

const UtangCreate = ({
    open, setOpen, setRefresh, accessToken, getCrediturId, getTotalUtang,
    setOpenAlert, setMessageAlert, setTypeAlert,
    detail
}) => {

    const [category, setCategory] = useState("utang")
    const [accountToId, setAccountToId] = useState("")
    const [nominal, setNominal] = useState("0")
    const [description, setDescription] = useState("")
    const [debiturCrediturId, setDebiturCrediturId] = useState("")
    const [date, setDate] = useState("")

    const [errorCrediturId, setErrorCrediturId] = useState(null)
    const [errorAccountToId, setErrorAccountToId] = useState(null)
    const [errorNominal, setErrorNominal] = useState(null)
    const [errorDescription, setErrorDescription] = useState(null)
    const [errorDate, setErrorDate] = useState(null)

    const [loading, setLoading] = useState(false)
    const [loadingAccountBallance, setLoadingAccountBallance] = useState(false)
    const [loadingDebiturCreditur, setLoadingDebiturCreditur] = useState(false)

    const [dataAccountBallance, setDataAccountBallance] = useState([])
    const fetchDataAccountBallance = async (accessToken) => {
        setLoadingAccountBallance(true)
        try {
            const response = await getAccountBallances({ accessToken });
            if (response.status === 200) {
                setDataAccountBallance(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingAccountBallance(false)
    };


    const [dataDebiturCreditur, setDataDebiturCreditur] = useState([])
    const [currentPage, setCurrentPage] = useState(0)
    const [lastPage, setLastPage] = useState(0)
    const [keyword, setKeyword] = useState("")

    const fetchDataDebiturCreditur = async (accessToken, keyword, page) => {
        setLoadingDebiturCreditur(true)
        try {
            const response = await getDebiturCreditur(accessToken, "", keyword, page,);
            if (response.status === 200) {
                setDataDebiturCreditur(response.data.data);
                setCurrentPage(response.data.meta.current_page)
                setLastPage(response.data.meta.last_page)
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingDebiturCreditur(false)
    };


    useEffect(() => {

    }, [])

    useEffect(() => {
        if (accessToken) {
            fetchDataAccountBallance(accessToken)
            fetchDataDebiturCreditur(accessToken, keyword, currentPage)
            if (getCrediturId) {
                setDebiturCrediturId(getCrediturId)
            }

        }
    }, [getCrediturId, accessToken])

    const dataOption = [
        { 'name': 'utang' },
        { 'name': 'utang dibayar' }
    ]

    const handleChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setNominal(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        try {

            setErrorCrediturId(null)
            setErrorAccountToId(null)
            setErrorNominal(null)
            setErrorDescription(null)
            setErrorDate(null)
            if (!date) {
                setErrorDate("Silahkan masukan tanggal transaksi terlebih dahulu..!")
            } else if (debiturCrediturId.length === 0) {
                setErrorCrediturId("Silahkan pilih debitur terlebih dahulu..!")
            } else if (accountToId.length === 0) {
                setErrorAccountToId("Silahkan pilih Kas / Bank terlebih dahulu..!")
            } else if (category === "utang dibayar" && dataAccountBallance.find(account => account.id === accountToId)?.ballance < Number(nominal)) {
                setErrorAccountToId("Saldo tidak cukup..!")
            } else if (Number(nominal) === 0) {
                setErrorNominal("Silahkan masukan nominal terlebih dahulu..!")
            } else if (category === "utang dibayar" && Number(nominal) > getTotalUtang) {
                setErrorNominal("Nominal melebihi total utang..!")
            } else if (description.length === 0) {
                setErrorDescription("Silahkan masukan keterangan terlebih dahulu..!")
            } else {

                const formData = new FormData()
                formData.append('debitur_creditur_id', debiturCrediturId)
                formData.append('category', category)
                formData.append('account_to_id', accountToId)
                formData.append('nominal', Number(nominal))
                formData.append('description', description)
                formData.append('date', date)

                const response = await postUtang(accessToken, formData)
                if (response.status === 201) {
                    setOpen(false)
                    setRefresh(true)
                    setNominal("0")
                    setDescription("0")
                    setDebiturCrediturId("")
                    setOpenAlert(true)
                    setMessageAlert("Transaksi berhasil ditambahkan..!")
                    setTypeAlert("success")
                } else {
                    if(response.status === 422){
                        setOpenAlert(true)
                        setMessageAlert(response.data.message ?? "Transaksi gagal ditambahkan..!" )
                        setTypeAlert("error")    
                    }else{
                        setOpenAlert(true)
                        setMessageAlert("Transaksi gagal ditambahkan..!")
                        setTypeAlert("error")
                    }
                }
            }
        } catch (error) {
            setOpenAlert(true)
            setMessageAlert("Transaksi gagal ditambahkan..!")
            setTypeAlert("error")
        }
        setLoading(false)

    }

    return (
        <LayoutModal name={"Transaksi utang"} open={open}>
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">

                <InputDefault
                    id="date"
                    type='date'
                    label="Tanggal Transaksi"
                    value={date}
                    setValue={setDate}
                    errorMessage={errorDate}
                />

                {!getCrediturId &&
                    <SelectFilterWithPaginate
                        label='Debitur'
                        placeholder='Pilih debitur'
                        options={dataDebiturCreditur.map(item => ({ value: item?.id, label: `${item?.name} (${item?.id})` }))}
                        onSelect={(e) => setDebiturCrediturId(e)}
                        currentPage={currentPage}
                        lastPage={lastPage}
                        setCurrentPage={setCurrentPage}
                        keyword={keyword}
                        setKeyword={setKeyword}
                        handleSearch={(e) => {
                            setKeyword(e.target.value)
                            fetchDataDebiturCreditur(accessToken, e.target.value, currentPage)
                        }}
                        loading={loadingDebiturCreditur}
                        errorMessage={errorCrediturId}
                    />
                }

                <SelectDefault
                    label='Jenis Transaksi'
                    options={dataOption.map((item) => ({ value: item?.name, label: item?.name }))}
                    onSelect={(e) => setCategory(e)}
                    disabled={detail === true ? false : true}
                />

                <SelectFilter
                    label={category === "utang" ? "Masuk ke" : "Diambil dari"}
                    placeholder='Pilih kas / bank'
                    options={dataAccountBallance.map(account => ({ value: account.id, label: `(${account?.id}) ${account?.name}` }))}
                    value={accountToId}
                    onSelect={(e) => setAccountToId(e)}
                    errorMessage={errorAccountToId}

                />

                {category === "utang dibayar" &&
                    <>
                        <div className='flex items-center justify-between w-full text-xs md:text-sm'>
                            <div className='font-semibold w-[100px]'>Saldo Akun :</div>
                            <div className='px-3 py-2 border-2 rounded-full w-full text-right'>
                                <CustomCurrency value={dataAccountBallance.find(account => account.id === accountToId)?.ballance ?? 0} />
                            </div>
                        </div>

                        <InputDefault
                            id="total_utang"
                            type='text'
                            label="Total Utang"
                            disabled={true}
                            value={formatCurrency(getTotalUtang.toString())}
                            handleChange={handleChange}
                            addClass="text-right"
                        />
                    </>
                }

                <InputDefault
                    id="nominal"
                    type='text'
                    label="Nominal"
                    inputMode="numeric"
                    value={formatCurrency(nominal)}
                    handleChange={handleChange}
                    addClass="text-right"
                    errorMessage={errorNominal ?? undefined}
                />

                <TextAreaDefault
                    id="description"
                    label="Keterangan"
                    value={description}
                    setValue={setDescription}
                    errorMessage={errorDescription}
                />

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault type="button" handleClick={() => {
                        setOpen(false)
                        if (!getCrediturId) {
                            setDebiturCrediturId("")
                        }
                        setNominal("0")
                        setDescription("")
                    }} addClass='bg-red-500 w-full '>
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default UtangCreate
import { postRequestUpdate } from "../../../../services/finance/request_update_service"

const SubmitUpdate = async (
    e,
    accessToken,
    data,
    loading, setLoading,
    open, setOpen,
    setOpenAlert, setMessageAlert, setTypeAlert,
) => {
    e.preventDefault()
    setLoading(true)
    try {
        const formData = new FormData()
        formData.append('transaction_id', data?.id)
        formData.append('category', data?.category)
        data?.nominal && formData.append("nominal", data?.nominal)
        data?.description && formData.append('description', data?.description)
        data?.detail && formData.append('detail', data?.detail)
        const response = await postRequestUpdate(accessToken, formData)
        
        if (response.status === 201) {
            setOpen && setOpen(false)
            setOpenAlert(true)
            setTypeAlert("success")
            setMessageAlert("Request update data berhasil dibuat..! silahkan tunggu konfirmasi dari admin")
        } else {
            alert("___")
        }
    } catch (error) {

    }
    setLoading(false)

}

export default SubmitUpdate
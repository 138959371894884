import { faCaretDown, faChevronLeft, faChevronRight, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import HandleClickOutside from '../utils/handle_click_outside';
import InputSearch from './input_search';

const SelectFilterWithPaginate = ({
    options,
    currentPage,
    lastPage,
    keyword,
    setCurrentPage,
    setKeyword,
    value,
    handleSearch,
    onSelect, label, addClass, placeholder, loading,
    handlePaginate,
    errorMessage,
    plusOption

}) => {

    const [isOpen, setIsOpen] = useState(false);
    const filterRef = useRef(null);
    const [filteredOptions, setFilteredOptions] = useState(options);
    const [selectedOption, setSelectedOption] = useState(null);

    const handleSelect = (option) => {
        setSelectedOption(option);
        onSelect(option.value);
        setIsOpen(false);
    };

    useEffect(() => {
        const selected = options.find(option => option.value === value);
        setSelectedOption(selected || null);
    }, [value, options]);

    useEffect(() => {
        setFilteredOptions(options);
    }, [options, currentPage, lastPage]);

    return (
        <>
            <div className="custom-select-container relative text-xs md:text-sm" ref={filterRef}>
                <label htmlFor="" className='font-semibold capitalize'>{label}</label>
                <div
                    className={`selected-option w-full bg-white rounded-full px-3 py-2 border-2 outline-none cursor-pointer flex items-center justify-between capitalize ${isOpen ? "border-2 border-cusom-navy" : ""} ${addClass}`}
                    onClick={() => setIsOpen(!isOpen)}
                >
                    {selectedOption ? selectedOption.label : placeholder}
                    <FontAwesomeIcon icon={faCaretDown} className={`transition-all ${isOpen ? '-rotate-90' : 'rotate-0'}`} />
                </div>
                {errorMessage && <p className='text-red-500 text-sm'>{errorMessage}</p>}
                {isOpen && (
                    <div
                        className="options-dropdown absolute top-[100%] border-2 rounded-xl max-h-[300px] min-w-full mt-2 bg-white z-[1000] px-3 pb-3 overflow-auto scrollbar-thin scrollbar-thumb-custom-navy scrollbar-track-custom-dark-yellow scrollbar-track-rounded-full scrollbar-thumb-rounded-full"
                    >
                        <div className="w-full bg-white sticky top-0 py-2">
                            <InputSearch
                                keyword={keyword}
                                handleSearch={handleSearch}
                                placeholder="Cari..."
                            />
                        </div>
                        <div>
                            {plusOption}
                        </div>
                        {
                            loading ? "Loading..." :
                                options.length > 0 ? (
                                    options.map((option) => (
                                        <div
                                            key={option.value}
                                            onClick={() => handleSelect(option)}
                                            className="option-item capitalize"
                                            style={{ padding: '8px', cursor: 'pointer', borderBottom: '1px solid #eee' }}
                                        >
                                            {option.label}
                                        </div>
                                    ))
                                ) : (
                                    <div className="no-options" style={{ padding: '8px' }}>
                                        No options found
                                    </div>
                                )}

                        {lastPage > 1 && (
                            <div className="flex items-center justify-center p-3">
                                <div className="pagination ">
                                    <button type='button' onClick={() => {
                                        if (currentPage > 1) {
                                            setCurrentPage(prevPage => {
                                                const newPage = prevPage - 1;
                                                handlePaginate(newPage);
                                                return newPage;
                                            });
                                        }
                                    }} disabled={currentPage <= 1}>
                                        <FontAwesomeIcon icon={faChevronLeft} />
                                    </button>
                                    <p>{currentPage} of {lastPage}</p>
                                    <button type='button' onClick={() => {
                                        if (currentPage < lastPage) {
                                            setCurrentPage(prevPage => {
                                                const newPage = prevPage + 1;
                                                handlePaginate(newPage);
                                                return newPage;
                                            });
                                        }
                                    }} disabled={currentPage >= lastPage}>
                                        <FontAwesomeIcon icon={faChevronRight} />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <HandleClickOutside setOpen={setIsOpen} filterRef={filterRef} />
        </>
    );
};

export default SelectFilterWithPaginate;

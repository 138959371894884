import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import { useEffect, useState } from "react"
import { exportNeraca, getNeraca, getNeracaAktiva, getNeracaBeban, getNeracaLabaRugi, getNeracaPasiva, getNeracaPendapatan } from "../../../../services/finance/neraca_service"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import { months } from "../../../../components/utils/month_data"
import SelectFilter from "../../../../components/form/select_filter"
import { useNavigate } from "react-router-dom"
import React from "react"
import NeracaTableBody from "./tbody"
import BtnDefault from "../../../../components/buttons/btn_default"
import CustomSpinner from "../../../../components/customs/spinner"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from "@fortawesome/free-solid-svg-icons"

const Neraca = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [year, setYear] = useState("");
    const [month, setMonth] = useState("");
    const [day, setDay] = useState("");

    const [data, setData] = useState([])
    const fetchData = async (accessToken, year, month, day) => {
        setLoading(true)
        try {
            const response = await getNeraca({ accessToken, params: { year, month, day } });
            if (response.status === 200) {
                setData(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    };

    const [dataAktiva, setDataAktiva] = useState([])
    const fetchDataAktiva = async (accessToken, year, month, day) => {
        setLoading(true)
        try {
            const response = await getNeracaAktiva({ accessToken, params: { year, month, day } });
            if (response.status === 200) {
                setDataAktiva(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    };

    const [dataPasiva, setDataPasiva] = useState([])
    const fetchDataPasiva = async (accessToken, year, month, day) => {
        setLoading(true)
        try {
            const response = await getNeracaPasiva({ accessToken, params: { year, month, day } });
            if (response.status === 200) {
                setDataPasiva(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    };

    const [dataPendapatan, setDataPendapatan] = useState([])
    const fetchDataPendapatan = async (accessToken, year, month, day) => {
        setLoading(true)
        try {
            const response = await getNeracaPendapatan({ accessToken, params: { year, month, day } });
            if (response.status === 200) {
                setDataPendapatan(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    };

    const [dataBeban, setDataBeban] = useState([])
    const fetchDataBeban = async (accessToken, year, month, day) => {
        setLoading(true)
        try {
            const response = await getNeracaBeban({ accessToken, params: { year, month, day } });
            if (response.status === 200) {
                setDataBeban(response.data.data);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    };

    const [dataDebitLabaRugi, setDataDebitLabaRugi] = useState(0)
    const [dataCreditLabaRugi, setDataCreditLabaRugi] = useState(0)
    const [dataBallanceLabaRugi, setDataBallanceLabaRugi] = useState(0)
    const fetchDataLabaRugi = async (accessToken, year, month, day) => {
        setLoading(true)
        try {
            const params = {
                'year' : year,
                'month' : month,
                'day' : day
            }
            const response = await getNeracaLabaRugi({ accessToken, params });
            if (response.status === 200) {
                setDataDebitLabaRugi(response.data.debit);
                setDataCreditLabaRugi(response.data.credit);
                setDataBallanceLabaRugi(response.data.ballance);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    };

    useEffect(() => {
        if (refresh) {
            fetchData(accessToken, year, month, day)
            fetchDataAktiva(accessToken, year, month, day)
            fetchDataPasiva(accessToken, year, month, day)
            fetchDataPendapatan(accessToken, year, month, day)
            fetchDataBeban(accessToken, year, month, day)
            fetchDataLabaRugi(accessToken, year, month, day)
        }
    }, [refresh])

    useEffect(() => {
        const accessToken = localStorage.getItem("access_token-terra_app")
        const userRole = localStorage.getItem("user_role-terra_app")
        if (accessToken && userRole) {
            if (["super admin", "admin", "keuangan"].includes(userRole)) {
                setAccessToken(accessToken)
                setUserRole(userRole)
                fetchData(accessToken, year, month, day)
                fetchDataAktiva(accessToken, year, month, day)
                fetchDataPasiva(accessToken, year, month, day)
                fetchDataPendapatan(accessToken, year, month, day)
                fetchDataBeban(accessToken, year, month, day)
                fetchDataLabaRugi(accessToken, year, month, day)
            } else {
                navigate("/403")
            }
        } else {
            navigate("/login")
        }
    }, [])

    let currentYear = new Date().getFullYear()
    const years = Array.from({ length: 20 }).map((_, index) => ({
        value: (currentYear - 10 + index).toString(),
        label: (currentYear - 10 + index).toString()
    }));

    const [loadingExport, setLoadingExport] = useState(false)
    const handleExport = async () => {
        setLoadingExport(true)
        try {
            const params = {
                year: year,
                month: month,
                day: day,
            }
            const response = await exportNeraca(accessToken, params)
            if (response.status === 200) {
                const generateRandomString = (length) => {
                    return Math.random().toString(36).substring(2, 2 + length);
                };
                const uniqid = Date.now() + '-' + generateRandomString(5);
                const fileName = `neraca_${uniqid}.xlsx`;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingExport(false)
    }

    return (
        <>
            <Helmet>
                <title>Neraca</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack link={"/dashboard/financial"} />
                    <p className="text-center">Neraca</p>
                    <div className="flex items-center justify-end gap-2">
                        <BtnDefault handleClick={handleExport}>
                            {loadingExport ? <CustomSpinner /> :
                                <>
                                    <FontAwesomeIcon icon={faFileExcel} />
                                    <p className='hidden md:flex'>Export Data</p>
                                </>
                            }
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="grid grid-cols-1 md:flex items-center gap-3 w-full sticky left-0 top-16 p-3 z-20 bg-white">
                    <SelectFilter
                        placeholder='Pilih Tahun'
                        options={years}
                        onSelect={(e) => {
                            setYear(e);
                            fetchData(accessToken, e, month, day);
                            fetchDataAktiva(accessToken, e, month, day)
                            fetchDataPasiva(accessToken, e, month, day)
                            fetchDataPendapatan(accessToken, e, month, day)
                            fetchDataBeban(accessToken, e, month, day)
                            fetchDataLabaRugi(accessToken, e, month, day)
                        }}
                        value={year}
                        plusOption={
                            <button
                                onClick={() => {
                                    setYear("")
                                    fetchData(accessToken, "", month, day);
                                    fetchDataAktiva(accessToken, "", month, day)
                                    fetchDataPasiva(accessToken, "", month, day)
                                    fetchDataPendapatan(accessToken, "", month, day)
                                    fetchDataBeban(accessToken, "", month, day)
                                    fetchDataLabaRugi(accessToken, "", month, day)
                                }}
                                className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                Pilih Hari
                            </button>
                        }
                        widthName="w-full md:w-[200px]"
                    />

                    <SelectFilter
                        placeholder='Pilih bulan'
                        options={months.map(item => ({ value: item.id, label: item.name }))}
                        value={month}
                        plusOption={
                            <button
                                onClick={() => {
                                    setMonth("")
                                    fetchData(accessToken, year, "", day);
                                    fetchDataAktiva(accessToken, year, "", day)
                                    fetchDataPasiva(accessToken, year, "", day)
                                    fetchDataPendapatan(accessToken, year, "", day)
                                    fetchDataBeban(accessToken, year, "", day)
                                    fetchDataLabaRugi(accessToken, year, "", day)
                                }}
                                className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                Pilih Hari
                            </button>
                        }
                        onSelect={(e) => {
                            setMonth(e)
                            fetchData(accessToken, year, e, day);
                            fetchDataAktiva(accessToken, year, e, day)
                            fetchDataPasiva(accessToken, year, e, day)
                            fetchDataPendapatan(accessToken, year, e, day)
                            fetchDataBeban(accessToken, year, e, day)
                            fetchDataLabaRugi(accessToken, year, e, day)
                        }}
                        widthName="w-full md:w-[200px]"
                    />

                    <SelectFilter
                        placeholder='Pilih Hari'
                        options={Array.from({ length: 31 }, (_, i) => ({
                            value: String(i + 1).padStart(2, '0'),
                            label: String(i + 1)
                        }))}
                        plusOption={
                            <button
                                onClick={() => {
                                    setDay("")
                                    fetchData(accessToken, year, month, "");
                                    fetchDataAktiva(accessToken, year, month, "")
                                    fetchDataPasiva(accessToken, year, month, "")
                                    fetchDataPendapatan(accessToken, year, month, "")
                                    fetchDataBeban(accessToken, year, month, "")
                                    fetchDataLabaRugi(accessToken, year, month, "")
                                }}
                                className="w-full py-2 hover:bg-gray-200 transition-colors border-b rounded-full">
                                Pilih Hari
                            </button>
                        }
                        value={day}
                        onSelect={(e) => {
                            setDay(e);
                            fetchData(accessToken, year, month, e);
                            fetchDataAktiva(accessToken, year, month, e)
                            fetchDataPasiva(accessToken, year, month, e)
                            fetchDataPendapatan(accessToken, year, month, e)
                            fetchDataBeban(accessToken, year, month, e)
                            fetchDataLabaRugi(accessToken, year, month, e)
                        }}
                        widthName="w-full md:w-[200px]"
                    />
                </div>

                <div className="px-3 flex flex-col gap-3">
                    <table className='min-w-max md:min-w-full max-w-screen-2xl pt-3'>
                        <thead className='sticky top-[220px] md:top-[128px] bg-white z-10'>
                            <tr className='bg-custom-dark-yellow'>
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">Akun</th>
                                <th className="px-3 py-2">Debit</th>
                                <th className="px-3 py-2">Kredit</th>
                                <th className="px-3 py-2 rounded-tr-xl">Saldo</th>
                            </tr>
                        </thead>
                        <tbody>
                            <NeracaTableBody name="Aktiva" data={dataAktiva} loading={loading} />
                            <NeracaTableBody
                                name="Pasiva"
                                data={dataPasiva}
                                loading={loading}
                                debit={dataDebitLabaRugi}
                                credit={dataCreditLabaRugi}
                                ballance={dataBallanceLabaRugi}
                            />
                            <NeracaTableBody name="Pendapatan" data={dataPendapatan} loading={loading} />
                            <NeracaTableBody name="HPP" data={dataBeban} loading={loading} />
                        </tbody>
                    </table>
                </div>

            </LayoutDashboard>
        </>
    )
}

export default Neraca
import { useState } from "react";
import BtnDefault from "../../../../components/buttons/btn_default"
import CustomSpinner from "../../../../components/customs/spinner";
import LayoutModal from "../../../../components/layouts/layout_modal"
import { format, getDaysInMonth, getMonth, getYear } from 'date-fns'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

const FilterExportJurnal = ({
    isOpen, setIsOpen,
    category, setCategory,
    year, setYear,
    periode, setPeriode,
    setFilter
}) => {



    const [loading, setLoading] = useState(false)

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())

    const generateYears = () => {
        const startYear = currentYear - 15
        const endYear = currentYear + 15

        return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);
    }

    // const generateMonths = () => {
    //     return Array.from({ length: 12 }, (_, i) => ({
    //         value: i + 1,
    //         label: format(new Date(2024, i), "MMMM"), // Nama bulan, misalnya "January"
    //     }));
    // };

    // const generateDays = () => {
    //     if (!year || !month) return [];
    //     const daysInMonth = getDaysInMonth(new Date(year, month - 1)); // -1 karena bulan dalam `Date` dimulai dari 0
    //     return Array.from({ length: daysInMonth }, (_, i) => i + 1);
    // };

    return (
        <LayoutModal
            name="Export Data"
            open={isOpen}
        >
            <div className="flex flex-col gap-3">
                <label className="space-y-1">
                    <span className="pl-3 font-semibold">Pilih Kategori</span>
                    <div className="relative flex items-center justify-center">
                        <select
                            value={category}
                            onChange={(e) => {
                                const newValue = e.target.value
                                setCategory(newValue)
                            }}
                            className="w-full rounded-full px-3 py-2 md:py-1 appearance-none border-2"
                        >
                            <option value="0" key="">Semua</option>
                            <option value="1" key="">Anual (1 Tahun)</option>
                            <option value="2" key="">Semester (6 Bulan)</option>
                            <option value="3" key="">Triwulan (3 Bulan)</option>
                        </select>
                        <FontAwesomeIcon icon={faChevronDown} className="absolute right-[8px] z-10 text-black" />
                    </div>
                </label>

                {["1", "2", "3"].includes(category) &&
                    <label className="space-y-1">
                        <span className="pl-3 font-semibold">Pilih Tahun</span>
                        <div className="relative flex items-center justify-center">
                            <select
                                value={year}
                                onChange={(e) => {
                                    const newValue = e.target.value
                                    setYear(newValue)
                                    setCurrentYear(newValue ? Number(newValue) : 2024)
                                }}
                                className="w-full rounded-full px-3 py-2 md:py-1 appearance-none border-2"
                            >
                                <option value="" key="">semua</option>
                                {generateYears().map((item) => (
                                    <option value={item}>{item}</option>
                                ))}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} className="absolute right-[8px] z-10 text-black" />
                        </div>
                    </label>
                }

                {["2", "3"].includes(category) && (
                    <label className="space-y-1">
                        <span className="pl-3 font-semibold">Pilih Periode</span>
                        <div className="relative flex items-center justify-center">
                            <select
                                value={periode}
                                onChange={(e) => {
                                    setPeriode(e.target.value)
                                }}
                                className="w-full rounded-full px-3 py-2 md:py-1 appearance-none border-2"
                            >
                                <option value="1" key="">
                                    {category === "2" ? "Semester" : "Triwulan"} 1
                                </option>
                                <option value="2" key="">
                                    {category === "2" ? "Semester" : "Triwulan"} 2
                                </option>
                                {category === "3" && (
                                    <>
                                        <option value="3" key="">Triwulan 3</option>
                                        <option value="4" key="">Triwulan 4</option>
                                    </>
                                )}
                            </select>
                            <FontAwesomeIcon icon={faChevronDown} className="absolute right-[8px] z-10 text-black" />
                        </div>
                    </label>
                )}
            </div>

            <div className="w-full flex items-center gap-3 justify-evenly">
                <BtnDefault type="button" handleClick={() => {
                    setIsOpen(false)
                }} addClass='bg-red-500 w-full '>
                    Kembali
                </BtnDefault>
                <BtnDefault
                    type="sbutton"
                    addClass='w-full'
                    handleClick={() => {
                        setFilter(true)
                        setIsOpen(false)
                    }}
                >
                    {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                </BtnDefault>
            </div>
        </LayoutModal>
    )
}


export default FilterExportJurnal
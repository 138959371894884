import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BtnDefault from "../../../components/buttons/btn_default";
import CustomSpinner from "../../../components/customs/spinner";
import InputDefault from "../../../components/form/input_default";
import LayoutModal from "../../../components/layouts/layout_modal";
import TextAreaDefault from "../../../components/form/text_area_default";
import { faFileImage } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useState } from "react";
import { updateConsument } from "../../../services/user_management/consument_service";
import { BaseUrl } from "../../../services/base/base_url";

const ConsumentUpdate = ({
  accessToken,
  isOpen,
  setIsOpen,
  setRefresh,
  getData,
  setAlert,
  setTypeAlert,
  setMessageAlert
}) => {
  const [loading, setLoading] = useState(false);

  const [id, setId] = useState(null);
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [ktp, setKtp] = useState(null);
  const [kk, setKK] = useState(null);

  const [errorName, setErrorName] = useState(null);
  const [errorPhoneNumber, setErrorPhoneNumber] = useState(null);
  const [errorEmail, setErrorEmail] = useState(null);
  const [errorAddress, setErrorAddress] = useState(null);
  const [errorKtp, setErrorKtp] = useState(null);
  const [errorKk, setErrorKk] = useState(null);

  const [imagePreviewKk, setImagePreviewKk] = useState(null);
  const [imagePreviewKtp, setImagePreviewKtp] = useState(null);

  useEffect(() => {
    if (getData) {
      setId(getData.id)
      setName(getData.name)
      setPhoneNumber(getData.phone_number)
      setEmail(getData.email)
      setAddress(getData.address)
    }
  }, [getData])

  const handleImageChange = (e, setImagePreview, setFile) => {
    const file = e.target.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
    } else {
      alert("Silakan unggah file gambar!");
    }
  };

  const handleDrop = (e, setImagePreview, setFile) => {
    e.preventDefault();
    const file = e.dataTransfer.files?.[0];
    if (file && file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
      setFile(file);
    } else {
      alert("Silakan unggah file gambar!");
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {

      setErrorName(null);
      setErrorPhoneNumber(null);
      setErrorEmail(null);
      setErrorAddress(null);
      setErrorKtp(null);
      setErrorKk(null);

      if (name.trim() === "") {
        setErrorName("Nama harus diisi..!");
      } else if (name.length < 3) {
        setErrorName("Nama minimal memiliki 3 huruf..!");
      } else if (phoneNumber.length < 11 || phoneNumber.length > 13) {
        setErrorPhoneNumber("Nomor telepon harus memiliki 11-13 digit..!")
      } else if (email.length < 1) {
        setErrorEmail("Email harus diisi..!");
      } else if (!email.includes("@gmail.com")) {
        setErrorEmail("Format email tidak valid..!");
      } else if (address.length < 1) {
        setErrorAddress("Alamat harus diisi..!");
      } else {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("phone_number", phoneNumber);
        formData.append("email", email);
        formData.append("address", address);
        if (ktp) formData.append("ktp", ktp);
        if (kk) formData.append("kk", kk);

        const response = await updateConsument(accessToken, formData, id);
        if (response.status === 200) {
          setIsOpen && setIsOpen(false);
          setRefresh && setRefresh(true);
          setAlert && setAlert(true);
          setTypeAlert && setTypeAlert("success");
          setMessageAlert && setMessageAlert("Data berhasil disimpan..!");
        } else {
          setAlert && setAlert(true);
          setTypeAlert && setTypeAlert("error");
          setMessageAlert && setMessageAlert("Gagal menyimpan data..!");
        }
      }
    } catch (error) {
      setAlert && setAlert(true);
      setTypeAlert && setTypeAlert("error");
      setMessageAlert && setMessageAlert("Silahkan periksa koneksi internet Anda..!");
    }
    setLoading(false);
  };

  return (
    <LayoutModal
      open={isOpen}
      setIsOpen={setIsOpen}
      name="Edit Konsumen"
      width="w-full lg:max-w-[70%]"
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-3">

        <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3 ">

          <div className="flex flex-col gap-3">
            <InputDefault
              id="name"
              type="text"
              label="Nama"
              placeholder="Nama Lengkap"
              value={name}
              setValue={setName}
              errorMessage={errorName ?? undefined}
            />

            <InputDefault
              id="phoneNumber"
              type="text"
              label="Nomor Telepon"
              inputMode="tel"
              placeholder="08xxxxxxxxxx"
              value={phoneNumber}
              handleChange={(e) => {
                const value = e.target.value;

                if (/^\d*$/.test(value) && value.length <= 13) {
                  setPhoneNumber(value);
                }
              }}
              errorMessage={errorPhoneNumber ?? undefined}
            />

            <InputDefault
              id="email"
              type="email"
              label="Email"
              placeholder="contoh@gmail.com"
              value={email}
              setValue={setEmail}
              errorMessage={errorEmail ?? undefined}
            />

            <TextAreaDefault
              id="note"
              name="Alamat"
              value={address}
              setValue={setAddress}
              rows={4}
              placeholder="Alamat Lengkap"
              errorMessage={errorAddress ?? undefined}
            />
          </div>

          <div className="flex flex-col gap-3">
            <div
              className="w-full flex flex-col"
              onDrop={(e) => handleDrop(e, setImagePreviewKtp, setKtp)}
              onDragOver={handleDragOver}
            >
              <p className="text-custom-navy font-semibold">Upload KTP</p>
              <label
                htmlFor="updateKtp"
                className="w-full h-[150px] rounded-xl border-2 border-dashed flex flex-col items-center justify-center gap-3 cursor-pointer relative overflow-hidden"
              >
                {!imagePreviewKtp ? getData && getData?.ktp ?
                  <img
                    src={BaseUrl + "/storage/" + getData?.ktp}
                    alt="Preview"
                    className="w-full h-full object-cover"
                  /> :
                  (
                    <>
                      <FontAwesomeIcon
                        icon={faFileImage}
                        className="text-custom-navy text-4xl "
                      />
                      <p className="text-custom-navy text-xl font-semibold">
                        Upload Gambar
                      </p>
                    </>
                  ) : (
                  <img
                    src={imagePreviewKtp}
                    alt="Preview"
                    className="w-full h-full object-cover"
                  />
                )}
              </label>
              <input
                id="updateKtp"
                type="file"
                className="hidden"
                onChange={(e) =>
                  handleImageChange(e, setImagePreviewKtp, setKtp)
                }
                accept="image/*"
              />
              {errorKtp && <p className="text-red-500 text-sm">{errorKtp}</p>}
            </div>

            <div
              className="w-full flex flex-col"
              onDrop={(e) => handleDrop(e, setImagePreviewKk, setKK)}
              onDragOver={handleDragOver}
            >
              <p className="text-custom-navy font-semibold text-start">
                Upload Kartu Keluarga
              </p>
              <label
                htmlFor="updateKk"
                className="w-full h-[150px] rounded-xl border-2 border-dashed flex flex-col items-center justify-center gap-3 cursor-pointer relative overflow-hidden"
              >
                {!imagePreviewKk ? getData && getData?.kk ?
                  <img
                    src={BaseUrl + "/storage/" + getData?.kk}
                    alt="Preview"
                    className="w-full h-full object-cover"
                  /> :
                  (
                    <>
                      <FontAwesomeIcon
                      icon={faFileImage}
                      className="text-custom-navy text-4xl "
                    />
                    <p className="text-custom-navy text-xl font-semibold">
                      Upload Gambar
                    </p>
                  </>
                ) : (
                  <img
                    src={imagePreviewKk}
                    alt="Preview"
                    className="w-full h-full object-cover"
                  />
                )}
              </label>
              <input
                id="updateKk"
                type="file"
                className="hidden"
                onChange={(e) => handleImageChange(e, setImagePreviewKk, setKK)}
                accept="image/*"
              />
              {errorKk && <p className="text-red-500 text-sm">{errorKk}</p>}
            </div>
          </div>

        </div>

        <div className="w-full flex items-center gap-3 justify-evenly">
          <BtnDefault
            type="button"
            handleClick={() => setIsOpen && setIsOpen(false)}
            addClass="bg-red-500 w-full text-white "
          >
            Kembali
          </BtnDefault>
          <BtnDefault type="submit" addClass="w-full text-white">
            {loading === true ? <CustomSpinner addClass="" /> : "Simpan"}
          </BtnDefault>
        </div>
      </form>
    </LayoutModal>
  );
};

export default ConsumentUpdate;

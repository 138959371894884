const InputDefault = ({
  id, type, placeholder, label,
  value, handleChange, setValue, errorMessage, addClass, disabled,
  inputMode,
  rounded
}) => {

  return (
    <div className="flex flex-col text-xs md:text-sm ">
      <label htmlFor={id} className="font-semibold capitalize">{label}</label>
      <input
        id={id}
        type={type ?? "text"}
        value={value ?? ''}
        inputMode={inputMode ?? "text"}
        onChange={(e) => {
          if (handleChange) {
            handleChange(e);
          } else if (setValue) {
            setValue(e.target.value);
          }
        }}

        disabled={disabled ?? false}
        
        placeholder={placeholder}
        className={`outline-none border-2 hover:border-custom-navy focus:border-custom-navy transition-colors ${!rounded ? 'rounded-full' : rounded} px-3 py-2 bg-white text-black  ${addClass}`}
      />
      {errorMessage && <p className="text-[10px] md:text-xs font-semibold text-red-500">{errorMessage}</p>}
    </div>
  );
};

export default InputDefault;
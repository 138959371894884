import { Helmet } from "react-helmet"
import LayoutDashboard from './../../../components/layouts/layout_dashboard';
import CustomHeader from './../../../components/customs/header';
import { Link } from 'react-router-dom';
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getRequestUpdate } from "../../../services/finance/request_update_service";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import InputSearch from "../../../components/form/input_search";

const Approved = () => {
    const [accessToken, setAccessToken] = useState("")
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)

    const [keyword, setKeyword] = useState("")
    const [data, setData] = useState([])
    const [category, setCategory] = useState('semua')
    const [currentPage, setCurrentPage] = useState(1)

    const fetchData = async (accessToken, currentPage) => {
        setloading(true)
        try {
            const params = {
                keyword: keyword,
                category: category,
                current_page : currentPage
            }
            const response = await getRequestUpdate(accessToken, params)
            if (response.status === 200) {
                setData(response.data.data)
            } else {

            }
        } catch (error) {

        }
        setloading(false)
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        if (accessToken) {
            setAccessToken(accessToken)
            fetchData(accessToken, currentPage)
        }
    }, [])

    return (
        <>
            <Helmet>
                <title>Persetujuan Update</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <p>Persetujuan Update</p>
                </CustomHeader>
                <div className="pt-3 px-3">
                    <form className='flex items-center relative gap-2 w-full' onSubmit={(e) => {
                        e.preventDefault()
                        setCurrentPage(1)
                        fetchData(accessToken, 1)
                    }}>
                        <InputSearch
                            placeholder='Cari data transaksi'
                            value={keyword}
                            handleSearch={(e) => {
                                setKeyword(e.target.value)
                            }}
                            addClass="w-full"
                        />
                        <button type='submit' className=' absolute right-[2px] bg-custom-dark-yellow text-custom-navy px-3 py-2 md:py-[2px] rounded-r-full'>
                            <FontAwesomeIcon icon={faSearch} />
                        </button>
                    </form>
                    <div>
                        
                    </div>
                </div>
                <div className="w-full p-3">
                    <table className="w-full text-sm">
                        <thead>
                            <tr className="bg-custom-dark-yellow">
                                <th className="px-3 py-2 rounded-tl-xl">No</th>
                                <th className="px-3 py-2">Status</th>
                                <th className="px-3 py-2">Kode Update</th>
                                <th className="px-3 py-2">Kategori</th>
                                <th className="px-3 py-2 rounded-tr-xl">Deskripsi</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((item, index) => (
                                <tr
                                    onClick={() => navigate(`detail/${item?.id}`)}
                                    className={`${index % 2 === 1 ? "bg-gray-200" : "bg-white"} hover:bg-gray-300 transition-colors cursor-pointer`}>
                                    <td className="px-3 py-1 text-center">{index + 1}</td>
                                    <td className="px-3 py-1 text-white text-center font-semibold">
                                        {item?.status?.name === "disetujui" ?
                                            <div className="bg-green-500 rounded-full px-3 py-1">
                                                {item?.status?.name}
                                            </div> :
                                            item?.status?.name === "ditolak" ?
                                                <div className="bg-red-500 rounded-full px-3 py-1">
                                                    {item?.status?.name}
                                                </div> :
                                                <div className="bg-yellow-500 rounded-full px-3 py-1">
                                                    {item?.status?.name}
                                                </div>
                                        }
                                    </td>
                                    <td className="px-3 py-1 text-center">{item.code}</td>
                                    <td className="px-3 py-1 text-center">{item?.category?.name}</td>
                                    <td className="px-3 py-1">{item?.detail}</td>
                                </tr>
                            ))}
                            <tr>
                                <td colSpan={10} className="bg-custom-dark-yellow py-4" />
                            </tr>
                        </tbody>
                    </table>
                </div>

            </LayoutDashboard>
        </>
    )
}

export default Approved
import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../components/customs/header"
import BtnBack from './../../../components/buttons/btn_back';
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { showRequestUpdate, updateRequestUpdate } from "../../../services/finance/request_update_service";
import BtnDefault from "../../../components/buttons/btn_default";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import FormatDateV2 from "../../../components/format/date_v2";
import CustomCurrency from "../../../components/customs/currency";
import CustomAlert from "../../../components/customs/alert";
import CustomSpinner from "../../../components/customs/spinner";
import FormatDate from "../../../components/format/date";

const MyTr = ({ name, value }) => {
    return (
        <tr>
            <td className="py-2">{name}</td>
            <td className="px-3">:</td>
            <td className="py-2">{value}</td>
        </tr>
    )
}

const ApprovedDetail = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()
    const [loading, setloading] = useState(false)
    const { id } = useParams()
    const [refresh, setRefresh] = useState(false)

    const [data, setData] = useState(null)

    const fetchData = async (accessToken) => {
        setloading(true)
        try {
            const response = await showRequestUpdate(accessToken, id)
            if (response.status === 200) {
                setData(response.data.data)
            } else {

            }
        } catch (error) {

        }
        setloading(false)
    }

    useEffect(() => {
        const accessToken = localStorage.getItem('access_token-terra_app')
        const userRole = localStorage.getItem('user_role-terra_app')

        if (accessToken) {
            setAccessToken(accessToken)
            setUserRole(userRole)
            fetchData(accessToken)
        }
    }, [id])

    useEffect(() => {
        if (refresh === true) {
            fetchData(accessToken)
            setRefresh(false)
        }
    }, [refresh])

    const [openAlert, setOpenAlert] = useState(false)
    const [messageAlert, setMessageAlert] = useState("")
    const [typeAlert, setTypeAlert] = useState("")

    const [loadingSubmit, setLoadingSubmit] = useState(false)
    const [openDropdown, setOpenDropdown] = useState(false)
    const [actionCategory, setActionCategory] = useState("tolak")
    const Submit = async (updateCategory) => {
        setLoadingSubmit(true)
        try {
            const data = new FormData()
            data.append('category', updateCategory)

            const response = await updateRequestUpdate(accessToken, id, data)
            if (response.status === 200) {
                setOpenDropdown(false)
                setRefresh(true)
                setOpenAlert(true)
                setTypeAlert("success")
                setMessageAlert("Aksi yang dilakukan berhasil..!")
            } else {
                setOpenAlert(true)
                setTypeAlert("error")
                setMessageAlert("Aksi yang dilakukan gagal..! Silahkan coba beberapa saat lagi")
            }
        } catch (error) {

        }
        setLoadingSubmit(false)
    }
    return (
        <>
            <Helmet>
                <title>Detail Update</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack link="/dashboard/approved" />
                    <p className="text-center">Detail Update</p>
                    {['super admin', 'admin'].includes(userRole) &&
                        <div className="flex items-center justify-end relative">
                            <BtnDefault handleClick={() => setOpenDropdown(!openDropdown)}>
                                <FontAwesomeIcon icon={faEdit} />
                                <span>Aksi</span>
                            </BtnDefault>

                            <div className={`absolute w-fit bg-white top-16 rounded-2xl flex flex-col items-center justify-center gap-3 px-5 transition-all duration-300 overflow-hidden ${openDropdown ? "h-[120px] border shadow-md" : "h-0 border-none shadow-none"} `}>
                                <button
                                    onClick={() => {
                                        setActionCategory("setujui")
                                        Submit("setujui")
                                    }}
                                    className="bg-green-500 hover:bg-green-600 transition-colors w-[150px] rounded-full py-2 font-semibold text-white flex items-center justify-center">
                                    {loadingSubmit && actionCategory !== "tolak" ? <CustomSpinner addClass="bg-white" /> : "Setujui"}
                                </button>
                                <button
                                    onClick={() => {
                                        setActionCategory("tolak")
                                        Submit("tolak")
                                    }}
                                    className="bg-red-500 hover:bg-red-600 transition-colors w-[150px] rounded-full py-2 font-semibold text-white flex items-center justify-center">
                                    {loadingSubmit && actionCategory === "tolak" ? <CustomSpinner addClass="bg-white" /> : "Tolak"}
                                </button>
                            </div>
                        </div>
                    }
                </CustomHeader>

                <div className="p-3 text-sm">
                    {/* Payment Data */}
                    <div className="accordion">
                        <input type="checkbox" id="toggle-16" className="accordion-toggle" />
                        <label htmlFor="toggle-16" className="accordion-title w-full px-3 py-2 bg-custom-navy rounded-xl font-bold text-custom-dark-yellow text-xs md:text-sm">
                            Detail Transaksi
                        </label>
                        <div className="accordion-content p-0">
                            <div className="min-h-0 -mt-3 overflow-auto">
                                <div className="w-full p-2 flex flex-col gap-3 border rounded-2xl overflow-auto scrollbar-thin scrollbar-track-custom-dark-yellow scrollbar-thumb-custom-navy">

                                    <div>
                                        <MyTr name="Tanggal" value={data?.transaction?.date ? <FormatDateV2 date={data?.transaction?.date} /> : ""} />
                                        <MyTr name="Kode Transaksi" value={data?.transaction?.code} />
                                        <MyTr name="Nominal" value={<CustomCurrency value={data?.transaction?.nominal ?? 0} />} />
                                        <MyTr name="Kategori Transaksi" value={data?.transaction?.category?.name} />
                                        {data?.transaction?.transaction_details?.map((item) => (
                                            <MyTr
                                                name={item?.type == "from" ? "Peruntukan" : "Diambil dari"}
                                                value={`${item?.account?.id} - ${item?.account?.name}`}
                                            />
                                        ))}
                                        <MyTr name="Keterangan Transaksi" value={data?.transaction?.description} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="pt-2">
                        <label className="accordion-title w-full px-3 py-2 bg-custom-navy rounded-xl font-bold text-custom-dark-yellow text-xs md:text-sm">
                            Data Perubahan
                        </label>
                        <div className="pl-3">
                            <MyTr name="Tanggal Request" value={data?.created_at && <FormatDate value={data?.created_at} />} />
                            <MyTr name="Kategori Request" value={data?.category?.name} />
                            <MyTr name="Kode Request" value={data?.code} />
                            <MyTr name="status" value={data?.status?.name === "disetujui" ?
                                <div className="bg-green-600 rounded-full px-3 py-1 font-semibold text-sm text-center text-white">
                                    {data?.status?.name}
                                </div> :
                                data?.status?.name === "ditolak" ?
                                    <div className="bg-red-600 rounded-full px-3 py- font-semibold text-sm text-center text-white">
                                        {data?.status?.name}
                                    </div> :
                                    <div className="bg-yellow-600 rounded-full px-3 py- font-semibold text-sm text-center text-white">
                                        {data?.status?.name}
                                    </div>
                            } />
                            <MyTr name="Dibuat Oleh" value={data?.created_by?.name} />
                            <MyTr name="Diupdate Oleh" value={data?.approved_by?.name} />
                            {data?.category?.name === "update" &&
                                <>
                                    <MyTr name="Nominal" value={<CustomCurrency value={data?.nominal ?? 0} />} />
                                    <MyTr name="Keterangan Transaksi" value={data?.description} />
                                </>
                            }
                            <MyTr name={data?.category?.name === "update" ? "Alasan Perubahan" : "Alasan Hapus data"} value={data?.detail} />
                        </div>
                    </div>

                </div>

                <CustomAlert
                    open={openAlert}
                    setOpen={setOpenAlert}
                    message={messageAlert}
                    type={typeAlert}
                />
            </LayoutDashboard>
        </>
    )
}

export default ApprovedDetail
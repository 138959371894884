import { useEffect } from "react";
import CustomCurrency from "../../../../components/customs/currency"
import { useState } from "react"
const NeracaTableBody = ({ name, data, loading, debit, credit, ballance }) => {

    const [totals, setTotals] = useState({
        debit: 0,
        credit: 0,
        balance: 0,
    });

    useEffect(() => {
        if (data) {
            const totalDebit = data.reduce((acc, item) => acc + item.debit, 0);
            const totalCredit = data.reduce((acc, item) => acc + item.credit, 0);
            const totalBalance = data.reduce((acc, item) => acc + item.balance, 0);
            setTotals({ debit: totalDebit, credit: totalCredit, balance: totalBalance });
        }
    }, [data]);

    return (
        <>

            <tr className="bg-yellow-300 sticky top-[250px] md:top-[168px] z-10">
                <td></td>
                <td className="px-3 py-2 md:py-0 font-semibold uppercase sticky left-0" colSpan={10}>{name}</td>
            </tr>

            {loading === true ?
                Array.from({ length: 20 }).map(() => (
                    <tr className="animate-pulse">
                        {Array.from({ length: 5 }).map(() => (
                            <td className='px-3 py-2'>
                                <div className='w-full h-5 bg-gray-300 rounded-full' />
                            </td>
                        ))}
                    </tr>
                )) :

                data && data.map((item, index) => (
                    <tr className={` ${index % 2 === 1 ? "bg-gray-200" : ""} hover:bg-gray-300 transition-colors`}>
                        <td className="px-3 py-2 text-center">
                            {index + 1}
                        </td>
                        <td className="px-3 py-2 capitalize">
                            {item.id}-{item.name}
                        </td>
                        <td className="px-3 py-2 text-end font-semibold">
                            {item?.id === '2-300' ? <CustomCurrency value={debit ?? 0} /> : <CustomCurrency value={item.debit} />}
                        </td>
                        <td className="px-3 py-2 text-end font-semibold">
                            {item?.id === '2-300' ? <CustomCurrency value={credit ?? 0} /> : <CustomCurrency value={item.credit} />}
                        </td>
                        <td className="px-3 py-2 text-end font-semibold">
                            {item?.id === '2-300' ? <CustomCurrency value={ballance ?? 0} /> : <CustomCurrency value={item.balance} />}
                        </td>
                    </tr>
                ))

            }

            <tr className="bg-green-300 ">
                <td className="px-3 py-2 md:py-0 font-semibold uppercase text-center " colSpan={2}>Total</td>
                <td className="px-3 py-2 md:py-0 font-semibold uppercase text-end">
                    <CustomCurrency value={totals.debit} />
                </td>
                <td className="px-3  font-semibold uppercase text-end">
                    <CustomCurrency value={totals.credit} />
                </td>
                <td className="px-3  font-semibold uppercase text-end">
                    <CustomCurrency value={totals.balance} />
                </td>
            </tr>

            <tr className="bg-custom-dark-yellow">
                <td className="px-3 py-4" colSpan={10}></td>
            </tr>
        </>
    )
}

export default NeracaTableBody
import { Helmet } from 'react-helmet';
import LayoutDashboard from '../../../../components/layouts/layout_dashboard';
import CustomHeader from '../../../../components/customs/header';
import BtnBack from '../../../../components/buttons/btn_back';
import BtnDefault from '../../../../components/buttons/btn_default';
import { exportJurnal, getTransaction } from '../../../../services/finance/transaction_service';
import CustomCurrency from '../../../../components/customs/currency';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFilter, faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import CustomPaginate from '../../../../components/customs/paginate';
import InputSearch from '../../../../components/form/input_search';
import CustomSpinner from '../../../../components/customs/spinner';
import { exportJurnalV2, getJurnal } from '../../../../services/finance/jurnal_service';
import FormatDateV2 from './../../../../components/format/date_v2';
import FilterExportJurnal from './filter_export';
const JurnalUmum = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openPiutangCreate, setOpenCreate] = useState(false)
    const [month, setMonth] = useState(null);
    const [day, setDay] = useState(null);

    const [data, setData] = useState([])
    const [debit, setDebit] = useState(0)
    const [credit, setCredit] = useState(0)
    const [totalData, setTotalData] = useState(0)
    const [currentPage, setCurrentPage] = useState(1)
    const [lastPage, setLastPage] = useState(1)
    let [from, setFrom] = useState(1)
    const [keyword, setKeyword] = useState("")
    const fetchData = async (accessToken, keyword, currentPage) => {
        setLoading(true)
        try {
            const params = {
                category: category,
                year: year,
                periode: periode,
                keyword: keyword,
                page: currentPage
            }

            const response = await getJurnal(accessToken, params)
            if (response.status === 200) {
                setDebit(response.data.debit)
                setCredit(response.data.credit)
                setData(response.data.data);
                setTotalData(response.data.meta.total);
                setCurrentPage(response.data.meta.current_page);
                setLastPage(response.data.meta.last_page);
                setFrom(response.data.meta.from);
            }
        } catch (error) {
            console.log("__")
        }
        setLoading(false)
    }

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')
        if (getAccessToken && getUserRole) {
            if (['super admin', 'admin', 'keuangan'].includes(getUserRole)) {
                setAccessToken(getAccessToken)
                fetchData(getAccessToken, keyword, currentPage)
            } else {
                navigate("/403")
            }
        } else {
            navigate("/login")
        }
    }, [])

    const MyTr = ({ name, value }) => {
        return (
            <tr>
                <td className="font-semibold capitalize">{name}</td>
                <td className="font-semibold px-2">:</td>
                <td>{value}</td>
            </tr>
        )
    }

    const [category, setCategory] = useState("0")
    const [year, setYear] = useState(new Date().getFullYear());
    let currentYear = new Date().getFullYear()
    const years = Array.from({ length: 20 }).map((_, index) => ({
        value: (currentYear - 10 + index).toString(),
        label: (currentYear - 10 + index).toString()
    }));
    const [periode, setPeriode] = useState("1")

    const [openFilter, setOpenFilter] = useState(false)
    const [filter, setFilter] = useState(false)
    useEffect(() => {
        if (filter) {
            fetchData(accessToken, keyword, currentPage)
        }
        setFilter(false)
    }, [filter])

    const [openFilterExport, setOpenFilterExport] = useState(false)
    const [filterExport, setFilterExport] = useState(false)
    const [yearExport, setYearExport] = useState(false)
    const [loadingExport, setLoadingExport] = useState(false)
    const handleExport = async () => {
        setLoadingExport(true)
        try {
            const params = {
                category: category,
                year: year,
                periode: periode
            }
            const response = await exportJurnalV2(accessToken, params)
            if (response.status === 200) {
                const generateRandomString = (length) => {
                    return Math.random().toString(36).substring(2, 2 + length);
                };
                const uniqid = Date.now() + '-' + generateRandomString(5);
                const fileName = `jurnal_umum_${uniqid}.xlsx`;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                // Membersihkan elemen setelah selesai
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingExport(false)
    }
    return (
        <>
            <Helmet>
                <title>Jurnal Umum</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack link={"/dashboard/financial"} />
                    <p className="text-center uppercase">Jurnal Umum</p>

                    <div className="flex items-center justify-end gap-3 text-sm">
                        <BtnDefault handleClick={handleExport}>
                            {loadingExport ? <CustomSpinner /> :
                                <>
                                    <FontAwesomeIcon icon={faFileExcel} />
                                    <p className='hidden md:flex'>Export Data</p>
                                </>
                            }
                        </BtnDefault>
                        <BtnDefault
                            type="button"
                            handleClick={() => setOpenFilter(true)}
                        >
                            <FontAwesomeIcon icon={faFilter} />
                            <p className='hidden md:flex'>Filter Data</p>
                        </BtnDefault>
                    </div>
                </CustomHeader>

                <div className="w-full flex flex-col md:flex-row items-start md:items-end md:justify-between px-3 py-2 bg-white z-10 sticky top-16 left-0 gap-3">
                    <div className="">
                        <MyTr name="Debet"
                            value={
                                <CustomCurrency value={debit} />
                            }
                        />
                        <MyTr name="Kredit"
                            value={
                                <CustomCurrency value={credit} />
                            }
                        />
                        {/* <MyTr name="ballance"
                            value={
                                <p className="font-semibold"><CustomCurrency value={debit - credit} /></p>
                            }
                        /> */}
                    </div>

                    <div className='w-full md:max-w-[300px]'>
                        Periode : {year}
                        <form className='flex items-center relative gap-2 w-full' onSubmit={(e) => {
                            e.preventDefault()
                            setCurrentPage(1)
                            fetchData(accessToken, keyword, 1)
                        }}>
                            <InputSearch
                                placeholder='Cari data transaksi'
                                value={keyword}
                                handleSearch={(e) => {
                                    setKeyword(e.target.value)
                                }}
                                addClass="w-full"
                            />
                            <button type='submit' className=' absolute right-[2px] bg-custom-dark-yellow text-custom-navy px-3 py-2 md:py-[2px] rounded-r-full'>
                                <FontAwesomeIcon icon={faSearch} />
                            </button>
                        </form>
                    </div>
                </div>

                <div className='w-full text-xs md:text-sm px-3 z-0'>
                    <table className={`max-w-screen-2xl ${data?.length === 0 || loading === true ? 'min-w-max md:min-w-full' : 'min-w-max'}`}>
                        <thead className="bg-custom-dark-yellow text-custom-navy sticky top-[198px] md:top-[136px] z-0">
                            <tr className="">
                                <th className="px-3 py-2 rounded-tl-xl" >No</th>
                                <th className="px-3 py-2" >Tanggal</th>
                                <th className="px-3 py-2" >Kode Transaksi</th>
                                <th className="px-3 py-2" >Kategori Transaksi</th>
                                <th className="px-3 py-2" >Keterangan Transaksi</th>
                                <th className="px-3 py-2" >Post</th>
                                <th className="px-3 py-2" >Debit</th>
                                <th className="px-3 py-2 rounded-tr-xl" >Kredit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                loading ? "Loading..!" :
                                    data?.length === 0 ?
                                        <tr className="">
                                            <td className="px-3 py-2" colSpan={20}>
                                                Tidak ada transaksi..!
                                            </td>
                                        </tr> :
                                        data.map((item, index) => (
                                            <>
                                                <tr className={`hover:bg-gray-400 transition-colors ${index % 2 === 1 ? 'bg-gray-200' : ''}`}>
                                                    <td className="px-3 py-2 text-center" rowSpan={3}>{from++}</td>
                                                    <td className="px-3 py-2" rowSpan={3}>
                                                        <FormatDateV2 date={item?.date} />
                                                    </td>
                                                    <td className="px-3 py-2" rowSpan={3}>
                                                        {item?.code}
                                                    </td>
                                                    <td className="px-3 py-2" rowSpan={3}>
                                                        {item?.category?.name}
                                                    </td>
                                                    <td className="px-3 py-2">
                                                        {item?.description}
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>

                                                <tr className={`hover:bg-gray-400 transition-colors ${index % 2 === 1 ? 'bg-gray-200' : ''}`}>
                                                    <td>
                                                        {item?.account_from?.id} -
                                                        {item?.account_from?.name}
                                                    </td>
                                                    <td className="px-3 py-2 text-end uppercase">
                                                        {item?.transaction_details?.[1]?.post?.charAt(0) === 'd' ? 'k' : "d"}
                                                    </td>
                                                    <td>
                                                        {item?.transaction_details?.[1]?.post?.charAt(0) === 'c' ? <CustomCurrency value={item?.nominal} /> : ""}
                                                    </td>
                                                    <td className="px-3 py-2 text-end">
                                                        {item?.transaction_details?.[1]?.post?.charAt(0) === 'd' ? <CustomCurrency value={item?.nominal} /> : ""}
                                                    </td>
                                                </tr>
                                                <tr className={`hover:bg-gray-400 transition-colors ${index % 2 === 1 ? 'bg-gray-200' : ''}`}>
                                                    <td>
                                                        {item?.account_to?.id} -
                                                        {item?.account_to?.name}
                                                    </td>
                                                    <td className="px-3 py-2 text-end uppercase">
                                                        {item?.transaction_details?.[1]?.post?.charAt(0) === 'c' ? 'k' : "d"}
                                                    </td>
                                                    <td className="px-3 py-2 text-end">
                                                        {item?.transaction_details?.[1]?.post?.charAt(0) === 'd' ? <CustomCurrency value={item?.nominal} /> : ""}
                                                    </td>
                                                    <td className="px-3 py-2 text-end">
                                                        {item?.transaction_details?.[1]?.post?.charAt(0) === 'c' ? <CustomCurrency value={item?.nominal} /> : ""}
                                                    </td>
                                                </tr>
                                            </>
                                        ))
                            }
                            {
                                lastPage < 2 && (
                                    <tr>
                                        <td colSpan={20} className='py-4 bg-custom-dark-yellow' />
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>


                {
                    lastPage > 1 && (
                        <div className="flex items-center justify-center md:justify-end w-full bg-custom-dark-yellow px-3 py-1 sticky bottom-0 left-0">
                            <CustomPaginate
                                currentPage={currentPage} lastPage={lastPage}
                                handlePaginate={(e) => {
                                    setCurrentPage(e)
                                    fetchData(accessToken, keyword, e)
                                }} />
                        </div>
                    )
                }
                {/* 
                <FilterJurnal
                    isOpen={openFilter}
                    setIsOpen={setOpenFilter}
                    year={year} setYear={setYear}
                    month={month} setMonth={setMonth}
                    day={day} setDay={setDay}
                    setFilter={setFilter}
                /> */}

                <FilterExportJurnal
                    isOpen={openFilter}
                    setIsOpen={setOpenFilter}
                    category={category} setCategory={setCategory}
                    year={year} setYear={setYear}
                    periode={periode} setPeriode={setPeriode}
                    setFilter={setFilter}
                />

            </LayoutDashboard >
        </>
    )
}

export default JurnalUmum
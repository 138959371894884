import { useEffect, useState } from "react"
import BtnDefault from "../../../../components/buttons/btn_default"
import CustomSpinner from "../../../../components/customs/spinner"
import LayoutModal from "../../../../components/layouts/layout_modal"
import TextAreaDefault from "../../../../components/form/text_area_default"
import InputDefault from "../../../../components/form/input_default"
import CustomCurrency from './../../../../components/customs/currency';
import FormatDateV2 from "../../../../components/format/date_v2"
import { postRequestUpdate } from "../../../../services/finance/request_update_service"
import SubmitUpdate from './function_update';

const TransactionUpdate = ({
    open, setOpen, setRefresh, accessToken,
    setOpenAlert, setMessageAlert, setTypeAlert,
    data, category
}) => {
    const [loading, setLoading] = useState(false)


    const handleChange = (e) => {
        const value = e.target.value;
        const numericValue = value.replace(/[^0-9]/g, '');
        setNominal(numericValue === '' ? '0' : numericValue);
    };

    const formatCurrency = (value) => {
        const numberValue = parseFloat(value.replace(/[^0-9.-]+/g, ""));
        return isNaN(numberValue) || numberValue < 1 ? '0' : numberValue.toLocaleString('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
        });
    };

    const [date, setDate] = useState("")
    const [nominal, setNominal] = useState("0")
    const [description, setDescription] = useState("")
    const [detail, setDetail] = useState("")

    const [errorDate, setErrorDate] = useState("")
    const [errorNominal, setErrorNominal] = useState("")
    const [errorDescription, setErrorDescription] = useState("")
    const [errorDetail, setErrorDetail] = useState("")

    const MyTr = ({ name, value }) => {
        return (
            <tr>
                <td className="py-1 font-semibold">{name}</td>
                <td className="py-1 px-2">:</td>
                <td className="py-1">{value}</td>
            </tr>
        )
    }



    return (
        <LayoutModal name={"Update Transaksi"} open={open} width="w-full">
            <form onSubmit={(e) => {
                const paramData = {
                    "id": data.id,
                    "category": category,
                    "nominal": nominal,
                    "description": description,
                    "detail": detail
                }
                SubmitUpdate(
                    e,
                    accessToken,
                    paramData,
                    loading,
                    setLoading,
                    open,
                    setOpen,
                    setOpenAlert,
                    setMessageAlert,
                    setTypeAlert
                )
            }} className="space-y-3">
                <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-3">
                    <div>
                        <MyTr name="Tanggal" value={data?.date ? <FormatDateV2 date={data?.date} /> : ""} />
                        <MyTr name="Kode Transaksi" value={data?.code} />
                        <MyTr name="Nominal" value={<CustomCurrency value={data?.nominal} />} />
                        <MyTr name="Kategori Transaksi" value={data?.category?.name} />
                        {data?.transaction_details?.map((item) => (
                            <>
                                <MyTr
                                    name={item?.type == "from" ? "Peruntukan" : "Diambil dari"}
                                    value={<>
                                        {item?.account?.id} - {item?.account?.name}

                                    </>}
                                />
                            </>
                        ))}
                        <MyTr name="Keterangan Transaksi" value={data?.description} />
                    </div>
                    <div>
                        <p className="w-full text-center bg-custom-dark-yellow py-2 rounded-md capitalize font-semibold">
                            {category === "update" ? "Silahkan Atur Perubahannya" : "Silahkan isi form ini"}
                        </p>
                        {
                            category === "update" &&
                            <>
                                <InputDefault
                                    id="name"
                                    type='text'
                                    label="Nominal Baru"
                                    inputMode={"numeric"}
                                    value={formatCurrency(nominal)}
                                    handleChange={handleChange}
                                    errorMessage={errorNominal ?? undefined}
                                    disabled={category === "delete" ? true : false}
                                />

                                <TextAreaDefault
                                    id="note"
                                    label="Keterangan Baru"
                                    value={description}
                                    setValue={setDescription}
                                    errorMessage={errorDescription}
                                    disabled={category === "delete" ? true : false}
                                />
                            </>
                        }

                        <TextAreaDefault
                            id="note"
                            label={category === "update" ? "Alasan Perubahan" : "Alasan hapus data"}
                            value={detail}
                            setValue={setDetail}
                            errorMessage={errorDetail}
                        />
                    </div>
                </div>

                <div className="w-full flex items-center gap-3 justify-evenly">
                    <BtnDefault
                        type="button"
                        handleClick={() => {
                            setOpen(false)
                        }}
                        addClass='bg-red-500 w-full '
                    >
                        Kembali
                    </BtnDefault>
                    <BtnDefault type="submit" addClass='w-full'>
                        {loading === true ? <CustomSpinner addClass='' /> : "Simpan"}
                    </BtnDefault>
                </div>
            </form>
        </LayoutModal>
    )
}

export default TransactionUpdate



import { Helmet } from "react-helmet"
import LayoutDashboard from "../../../../components/layouts/layout_dashboard"
import CustomHeader from "../../../../components/customs/header"
import BtnBack from "../../../../components/buttons/btn_back"
import { exportCashflow, getCashflow, getCashflowBeban, getCashflowBebanTotal, getCashflowCashOnHand, getCashflowPendapatan, getCashflowPendapatanTotal } from "../../../../services/finance/cashflow_service"
import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import CustomCurrency from "../../../../components/customs/currency"
import SelectDefault from "../../../../components/form/select_default"
import CustomSpinner from "../../../../components/customs/spinner"
import TableRow from "./table_row"
import TableRowCashflow from "./table_row"
import BtnDefault from './../../../../components/buttons/btn_default';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFileExcel } from "@fortawesome/free-solid-svg-icons"

const Cashflow = () => {
    const [accessToken, setAccessToken] = useState("")
    const [userRole, setUserRole] = useState("")
    const navigate = useNavigate()

    const [loading, setLoading] = useState(false)
    const [refresh, setRefresh] = useState(false)

    const [openPiutangCreate, setOpenCreate] = useState(false)

    const [dataCashOnHand, setDataCashOnHand] = useState([])
    const fetchDataCashOnHand = async (accessToken, loading, refresh) => {
        if (loading) {
            setLoading(true)
        }
        if (refresh) {
            setRefresh(true)
        }
        try {
            const response = await getCashflowCashOnHand({ accessToken, params: { year: currentYear } })
            if (response.status === 200) {
                setDataCashOnHand(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        if (loading) {
            setLoading(false)
        }
        if (refresh) {
            setRefresh(false)
        }
    }

    const [dataPendapatan, setDataPendapatan] = useState([])
    const fetchDataPendapatan = async (accessToken, loading, refresh) => {
        if (loading) {
            setLoading(true)
        }
        if (refresh) {
            setRefresh(true)
        }
        try {
            const response = await getCashflowPendapatan({ accessToken, params: { year: currentYear } })
            if (response.status === 200) {
                setDataPendapatan(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        if (loading) {
            setLoading(false)
        }
        if (refresh) {
            setRefresh(false)
        }
    }

    const [dataTotalPendapatan, setDataTotalPendapatan] = useState([])
    const fetchDataTotalPendapatan = async (accessToken, loading, refresh) => {
        if (loading) {
            setLoading(true)
        }
        if (refresh) {
            setRefresh(true)
        }
        try {
            const response = await getCashflowPendapatanTotal({ accessToken, params: { year: currentYear } })
            if (response.status === 200) {
                console.log(response.data)
                setDataTotalPendapatan(response.data)
            }
        } catch (error) {
            console.log("__")
        }
        if (loading) {
            setLoading(false)
        }
        if (refresh) {
            setRefresh(false)
        }
    }

    const [dataBeban, setDataBeban] = useState([])
    const fetchDataBeban = async (accessToken, loading, refresh) => {
        if (loading) {
            setLoading(true)
        }
        if (refresh) {
            setRefresh(true)
        }
        try {
            const response = await getCashflowBeban({ accessToken, params: { year: currentYear } })
            if (response.status === 200) {
                setDataBeban(response.data.data)
            }
        } catch (error) {
            console.log("__")
        }
        if (loading) {
            setLoading(false)
        }
        if (refresh) {
            setRefresh(false)
        }
    }

    const [dataTotalBeban, setDataTotalBeban] = useState([])
    const fetchDataTotalBeban = async (accessToken, loading, refresh) => {
        if (loading) {
            setLoading(true)
        }
        if (refresh) {
            setRefresh(true)
        }
        try {
            const response = await getCashflowBebanTotal({ accessToken, params: { year: currentYear } })
            if (response.status === 200) {
                setDataTotalBeban(response.data)
            }
        } catch (error) {
            console.log("__")
        }
        if (loading) {
            setLoading(false)
        }
        if (refresh) {
            setRefresh(false)
        }
    }

    const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
    
    const thRefs = useRef([]);

    useEffect(() => {
        const getAccessToken = localStorage.getItem('access_token-terra_app')
        const getUserRole = localStorage.getItem('user_role-terra_app')

        if (getAccessToken && getUserRole) {
            if (["super admin", "admin", "keuangan"].includes(getUserRole)) {
                setUserRole(getUserRole)
                setAccessToken(getAccessToken)
                fetchDataCashOnHand(getAccessToken, true, false)
                fetchDataPendapatan(getAccessToken, true, false)
                fetchDataTotalPendapatan(getAccessToken, true, false)
                fetchDataBeban(getAccessToken, true, false)
                fetchDataTotalBeban(getAccessToken, true, false)
            } else {
                navigate("/403")
            }
        } else {
            navigate("/login")
        }

        const calculateLeft = () => {
            let totalWidth = 0;
            thRefs.current.forEach((th) => {
                if (th) {
                    th.style.left = `${totalWidth}px`;
                    totalWidth += th.offsetWidth;
                }
            });
        };

        calculateLeft();
        window.addEventListener('resize', calculateLeft);

        return () => {
            window.removeEventListener('resize', calculateLeft);
        };

    }, [accessToken, currentYear])


    // Buat array tahun dengan rentang dari -100 hingga +100
    const generateYearOptions = (year) => {
        const years = [];
        for (let i = year - 100; i <= year + 100; i++) {
            years.push(i);
        }
        return years;
    };

    // Daftar tahun +10 dan -10 dari tahun yang dipilih
    const yearOptions = generateYearOptions(currentYear).filter(
        (year) => year >= currentYear - 10 && year <= currentYear + 10
    );

    const [loadingExport, setLoadingExport] = useState(false)
    const handleExport = async () => {
        setLoadingExport(true)
        try {
            const params = {
                year: currentYear,
            }
            const response = await exportCashflow(accessToken, params)
            if (response.status === 200) {
                const generateRandomString = (length) => {
                    return Math.random().toString(36).substring(2, 2 + length);
                };
                const uniqid = Date.now() + '-' + generateRandomString(5);
                const fileName = `cashflow_${uniqid}.xlsx`;

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", fileName);
                document.body.appendChild(link);
                link.click();

                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(url);
            }
        } catch (error) {
            console.log("__")
        }
        setLoadingExport(false)
    }

    return (
        <>
            <Helmet>
                <title>Arus Kas</title>
            </Helmet>
            <LayoutDashboard>
                <CustomHeader>
                    <BtnBack link={"/dashboard/financial"} />
                    <p className='text-center'>Data Arus Kas</p>
                    <div className="flex justify-end gap-3">
                        <BtnDefault handleClick={handleExport}>
                            {loadingExport ? <CustomSpinner /> :
                                <>
                                    <FontAwesomeIcon icon={faFileExcel} />
                                    <p className='hidden md:flex'>Export Data</p>
                                </>
                            }
                        </BtnDefault>
                        <SelectDefault
                            id="year"
                            options={yearOptions.map(year => ({ value: year, label: year }))}
                            value={currentYear}
                            onSelect={(value) => setCurrentYear(value)}

                        />
                    </div>
                </CustomHeader>

                <div className="w-full h-2 bg-white sticky left-0 top-16 z-10" />
                <div className="w-full px-3 pb-5">
                    <table className={`max-w-[2xl] min-w-max`}>
                        <thead className="bg-custom-dark-yellow text-custom-navy sticky top-[70px] z-20 ">
                            <tr className="">
                                <th
                                    className="px-3 py-2 border-r sticky  bg-custom-dark-yellow rounded-tl-xl" rowSpan={2}>
                                    No
                                </th>
                                <th
                                    ref={(el) => (thRefs.current[2] = el)}
                                    className="px-3 py-2 border-r md:sticky top-[70px] left-0 z-10 bg-custom-dark-yellow" rowSpan={2}>
                                    Nama Akun
                                </th>
                                {Array.from({ length: 12 }, (_, i) => {
                                    const month = new Date(0, i).toLocaleString('default', { month: 'long' });
                                    return (
                                        <th key={i} className={`px-3 py-2 border-r border-b ${month === "Desember" && "rounded-tr-xl "}`}>{month}</th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>

                            <tr className="bg-yellow-300 sticky top-[100px] md:top-[113px] z-10">
                                <td></td>
                                <td colSpan={30} className="px-3 py-2 font-semibold sticky left-0 ">Cash On Hand</td>
                            </tr>

                            <tr>
                                <td className="px-3 py-2 sticky z-0 text-center bg-white">1</td>
                                <td className="px-3 py-2 md:sticky left-0  capitalize bg-white">
                                    Total Cash On Hand
                                </td>
                                {dataCashOnHand.map((item) => (
                                    <td
                                        className={`px-3 py-2 text-center font-semibold group-hover:bg-gray-300 transition-colors`}
                                    >
                                        <CustomCurrency value={item?.balance ?? 0} />
                                    </td>
                                ))}
                            </tr>

                            <TableRowCashflow
                                type="pendapatan"
                                name="Pendapatan"
                                loading={loading}
                                data={dataPendapatan}
                                navigate={navigate}
                                currentYear={currentYear}
                            />

                            {/* <tr>
                                <td className="px-3 py-2 sticky z-0 text-center bg-white">1</td>
                                <td className="px-3 py-2 md:sticky left-0  capitalize bg-white">
                                    Total Cash On Hand
                                </td>
                                {dataCashOnHand.map((item) => (
                                    <td
                                        className={`px-3 py-2 text-center font-semibold group-hover:bg-gray-300 transition-colors`}
                                    >
                                        <CustomCurrency value={item?.$debit ?? 0 + item?.balance ?? 0} />
                                    </td>
                                ))}
                            </tr> */}

                            <tr className="bg-green-400">
                                <td className="px-3 py-2 sticky z-0 text-center bg-green-400"></td>
                                <td className="px-3 py-2 md:sticky left-0  capitalize bg-green-400 font-semibold">TOTAL CASH RECEIPTS</td>
                                {dataTotalPendapatan &&
                                    Object.keys(dataTotalPendapatan).map((month, index) => (
                                        <td
                                            key={`${month}-${index}`}
                                            className={`px-3 py-2 text-center font-semibold group-hover:bg-gray-300 transition-colors`}
                                        >
                                            <CustomCurrency value={dataTotalPendapatan[month]?.actual} />
                                        </td>
                                    ))}
                            </tr>

                            <tr className="bg-blue-400">
                                <td className="px-3 py-2 sticky z-0 text-center bg-blue-400"></td>
                                <td className="px-3 py-2 md:sticky left-0  capitalize bg-blue-400 font-semibold">TOTAL CASH AVAILABLE (before cash out)</td>
                                {dataCashOnHand.map((item) => (
                                    <td
                                        className={`px-3 py-2 text-center font-semibold group-hover:bg-gray-300 transition-colors`}
                                    >
                                        <CustomCurrency value={(item?.monthly_debit ?? 0)} />
                                    </td>
                                ))}
                            </tr>

                            <TableRowCashflow
                                type="pengeluaran"
                                name="Pengeluaran"
                                loading={loading}
                                data={dataBeban}
                                navigate={navigate}
                                currentYear={currentYear}
                            />

                            <tr className="bg-green-400">
                                <td className="px-3 py-2 sticky z-0 text-center bg-green-400"></td>
                                <td className="px-3 py-2 md:sticky left-0  capitalize bg-green-400 font-semibold">TOTAL CASH PAID OUT</td>
                                {dataTotalBeban &&
                                    Object.keys(dataTotalBeban).map((month, index) => (
                                        <td
                                            key={`${month}-${index}`}
                                            className={`px-3 py-2 text-center font-semibold group-hover:bg-gray-300 transition-colors`}
                                        >
                                            <CustomCurrency value={dataTotalBeban[month]?.actual} />
                                        </td>
                                    ))}
                            </tr>

                            <tr className="bg-blue-300">
                                <td className="px-3 py-2 sticky z-0 text-center bg-blue-300"></td>
                                <td className="px-3 py-2 md:sticky left-0  capitalize z-10 bg-blue-300 font-semibold">CASH POSITION (end of month)</td>
                                {dataCashOnHand.map((item) => (
                                    <td
                                        className={`px-3 py-2 text-center font-semibold group-hover:bg-gray-300 transition-colors`}
                                    >
                                        <CustomCurrency value={(item?.monthly_total ?? 0)} />
                                    </td>
                                ))}
                            </tr>

                            <tr className="bg-custom-dark-yellow">
                                <td className="px-3 py-4" colSpan={30}></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </LayoutDashboard>
        </>
    )
}

export default Cashflow
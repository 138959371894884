
import ApiService from '../base/api_service';

export const getRequestUpdate    = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/request-update`, {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const showRequestUpdate    = async (accessToken, id) => {
    try {
        const response = await ApiService.get(`/request-update/show/${id}`, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const postRequestUpdate = async (accessToken, data) => {
    try {
        const response = await ApiService.post('/request-update/create', data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const updateRequestUpdate = async (accessToken, id, data) => {
    try {
        const response = await ApiService.post(`/request-update/update/${id}`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}
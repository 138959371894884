
import ApiService from '../base/api_service';

export const getCashflow = async ({ accessToken, params }) => {
    try {
        const response = await ApiService.get('/cashflow', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getCashflowCashOnHand = async ({ accessToken, params }) => {
    try {
        const response = await ApiService.get('/cashflow/cashonhand', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getCashflowPendapatan = async ({ accessToken, params }) => {
    try {
        const response = await ApiService.get('/cashflow/pendapatan', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getCashflowPendapatanTotal = async ({ accessToken, params }) => {
    try {
        const response = await ApiService.get('/cashflow/pendapatan/total', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getCashflowBeban = async ({ accessToken, params }) => {
    try {
        const response = await ApiService.get('/cashflow/beban', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const getCashflowBebanTotal = async ({ accessToken, params }) => {
    try {
        const response = await ApiService.get('/cashflow/beban/total', {
            params: params,
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response

    } catch (error) {
        return error.response;
    }
}

export const showCashflow = async ({ accessToken, id, year }) => {
    try {
        const response = await ApiService.get(`/cashflow/show/${id}`, {
            params: { year },
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response;
    }
}

export const updateCashflow = async ({ accessToken, id, data }) => {
    try {
        const response = await ApiService.post(`/cashflow/update/${id}`, data, {
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })

        return response
    } catch (error) {
        return error.response;
    }
}

export const exportCashflow = async (accessToken, params) => {
    try {
        const response = await ApiService.get(`/cashflow/export`, {
            params: params,
            responseType: 'blob',
            headers: {
                Authorization: accessToken,
                'Content-Type': 'application/json'
            }
        })
        return response
    } catch (error) {
        return error.response;
    }
} 
